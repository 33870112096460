
<table cellpadding="3" cellspacing="3" style="width:99%;margin:0 auto;border:solid 1px black;">

  <tr><td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd"><button id="printbtn" color="primary" style="font-size:12pt;" mat-button onclick="window.print();">Print This Page</button></td></tr>
  
  <!------------------------------------------------------------------------------------->
  <tr><td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">GENERAL RESOURCES</td></tr>
  <tr>
    <td style="border-bottom:solid 1px black;width:100px;">RESOURCE ICON</td>
    <td style="border-bottom:solid 1px black;width:200px;">RESOURCE NAME</td>
    <td style="border-bottom:solid 1px black;width:300px;">WHAT DOES THIS RESOURCE DO?</td>
    <td style="border-bottom:solid 1px black;width:150px;">VISIT THE WEBSITE</td>
    <td style="border-bottom: solid 1px black; width: 150px;">CALL</td>
  </tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.General )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <!------------------------------------------------------------------------------------->
  <!------------------------------------------------------------------------------------->
  <tr><td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">FINANCIAL RESOURCES</td></tr>
  <tr>
    <td style="border-bottom:solid 1px black;width:100px;">RESOURCE ICON</td>
    <td style="border-bottom:solid 1px black;width:200px;">RESOURCE NAME</td>
    <td style="border-bottom:solid 1px black;width:300px;">WHAT DOES THIS RESOURCE DO?</td>
    <td style="border-bottom:solid 1px black;width:150px;">VISIT THE WEBSITE</td>
    <td style="border-bottom: solid 1px black; width: 150px;">CALL</td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Financial Assistance Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.FinancialNeeds )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Financial Management Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralFinancial )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Legal Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Legal )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Housing Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Housing )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Assistance Buying or Upgrading a Home</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.HousingALT )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Transportation Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Transportation )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Food Insecurity Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.FoodInsecurity )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>



  <tr><td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">VOCATIONAL RESOURCES</td></tr>
  <tr>
    <td style="border-bottom:solid 1px black;width:100px;">RESOURCE ICON</td>
    <td style="border-bottom:solid 1px black;width:200px;">RESOURCE NAME</td>
    <td style="border-bottom:solid 1px black;width:300px;">WHAT DOES THIS RESOURCE DO?</td>
    <td style="border-bottom:solid 1px black;width:150px;">VISIT THE WEBSITE</td>
    <td style="border-bottom: solid 1px black; width: 150px;">CALL</td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">WORK RESOURCES</td></tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Job Search Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LookingForWork )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Work Functioning Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Employed )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">EDUCATION RESOURCES</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LookingForEducation )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.InSchool )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">CAREGIVING RESOURCES</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.CareGiving )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>



  <tr><td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">HEALTH RESOURCES</td></tr>
  <tr>
    <td style="border-bottom:solid 1px black;width:100px;">RESOURCE ICON</td>
    <td style="border-bottom:solid 1px black;width:200px;">RESOURCE NAME</td>
    <td style="border-bottom:solid 1px black;width:300px;">WHAT DOES THIS RESOURCE DO?</td>
    <td style="border-bottom:solid 1px black;width:150px;">VISIT THE WEBSITE</td>
    <td style="border-bottom: solid 1px black; width: 150px;">CALL</td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">HEALTH RELATED BEHAVIORS</td></tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Healthy Eating</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.HealthyEating )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Exercise Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.PhysicalActivity )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Sleep Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Sleep )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>


  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Leisure Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LeisureActivity )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Smoking Cessation Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Smoking )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Alcohol Use Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.AlcoholMisuse )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Substance Use Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.SubstanceMisuse )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">MENTAL HEALTH</td></tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Thoughts of Suicide</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.SuicideIdeation )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Physical or Mental Health Care Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealth )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Health Resources Outside of VA</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealthOVA )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Self-Help Mental Health Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealthALT )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Depression Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Depression )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Anxiety Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Anxiety )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">PTSD Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.PTSD )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">GENERAL HEALTH</td></tr>
  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Physical or Mental Health Care Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealth )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>


  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Health Care Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.HealthCare )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <tr><td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Connection-Focused Resources</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.SenseOfPurpose )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Happiness )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>


  <tr><td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">SOCIAL RESOURCES</td></tr>
  <tr>
    <td style="border-bottom:solid 1px black;">RESOURCE ICON</td>
    <td style="border-bottom:solid 1px black;">RESOURCE NAME</td>
    <td style="border-bottom:solid 1px black;">WHAT DOES THIS RESOURCE DO?</td>
    <td style="border-bottom:solid 1px black;">VISIT THE WEBSITE</td>
    <td style="border-bottom:solid 1px black;">CALL</td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">COMMUNITY RELATIONSHIP RESOURCES</td></tr>

  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.BroaderSocial )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>


  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">INTIMATE RELATIONSHIP RESOURCES</td></tr>

  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.IntimateRelationship )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <tr><td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">GENDER/RACE/SEXUAL IDENTITY RESOURCES</td></tr>
  <tr>
    <td style="border-bottom:solid 1px black;">RESOURCE ICON</td>
    <td style="border-bottom:solid 1px black;">RESOURCE NAME</td>
    <td style="border-bottom:solid 1px black;">WHAT DOES THIS RESOURCE DO?</td>
    <td style="border-bottom:solid 1px black;">VISIT THE WEBSITE</td>
    <td style="border-bottom:solid 1px black;">CALL</td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">RESOURCES FOR WOMEN VETERANS</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Women )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">RESOURCES FOR MINORITY VETERANS</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.POC )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>
  <tr><td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">RESOURCES FOR LGBTQ+ VETERANS</td></tr>
  <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LGBTQ )">
    <td class="resourceimg">
      <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
    </td>
    <td>
      {{res.resourceName}}
    </td>
    <td>
      {{res.resourceDescription}}
    </td>
    <td >
      Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
    </td>
    <td>
      {{res.resourcePhone}}
    </td>
  </tr>

  <!------------------------------------------------------------------------------------->
</table>
