
import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, DoCheck, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute, Router } from '@angular/router';
import { TNTResponse, UserInformation, DomainStatus, ResourceCategory, TNTResource, TNTReportActivity, ActivityType } from '../../models/models';
import { SurveyAPI } from '../../services/surveyAPI.service';
import { MatTab } from '@angular/material/tabs';
import { timer, interval } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';



@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit, DoCheck, OnChanges {

  responseInfo!: TNTResponse;
  public DomainStatus = DomainStatus;
  public ResourceCategory = ResourceCategory;
  _resources = Array<TNTResource>();
  public secondsViewed: number = 0;
  public responses: TNTResponse[];
  public token: string;
  public printMode: boolean = false;
  public nextButtonClicked: boolean = false;

  constructor(private sapi: SurveyAPI, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {
    this.token = "";
    this.responses = Array<TNTResponse>();

    console.log("constructor()");
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("ngOnChanges()");

  }
  ngDoCheck(): void {

  }

   async ngOnInit(): Promise<void> {

     console.log("ngOnInit()");
     var responseID = this.route.snapshot.paramMap.get('id');
     if (responseID != null && this.responseInfo == null) {

       this.responseInfo = await this.sapi.getResponseWithDetails(responseID);
       if (this.responseInfo != null) {
         this.token = this.responseInfo.user.token;
         this.responses = await this.sapi.getResponseHistoryByToken(this.token,true);
         this.responseInfo.report = JSON.parse(this.responseInfo?.reportModel);
         this.responseInfo.dateReportViewed = new Date();
       }

       await this.loadResources();
     }


    interval(10000).subscribe(n =>
      this.updateResponseTime(n)
    );


  }

  print(): void {
    this.printMode = true;
    setTimeout("window.print()", 2000);

  }

  updateResponseTime(seconds: number): void {
    this.responseInfo.reportViewedSeconds = seconds * 10;
    this.sapi.postResponse(this.responseInfo);
    console.log("updateResponseTime" + seconds * 10);
  }

  async updateResponseLog(url: string): Promise<void> {
    let ra: TNTReportActivity = new TNTReportActivity();
    ra.activityType = ActivityType.UrlClick;
    ra.activity = url;
    ra.responseID = this.responseInfo.responseID;
    ra = await this.sapi.postReportActivity(ra);
    console.log("Link clicked:" + url);
  }

  gotoFollowUp(): void {
    //this.router.navigate(['survey/' + this.token]);
    window.open('#/survey/' + this.token,'_blank')
  }


  async loadReportDetail(responseID: string | any): Promise<void> {

  }
  async loadResources() {
    this._resources = await this.sapi.getResourcesByCategory("");
  }

  finishSurveyDialog() {
    this.openDialog("", "Thank you for your participation in this study! You will be mailed a $50 Amazon gift card for your time. You will receive the gift card after the survey completion process has ended, which may take up to two months.");
    this.nextButtonClicked = true;
  }

  openDialog(title: string, message: string): void {
    this.dialog.open(FinishSurveyComponentDialog, { data: { title: title, message: message } });
  }

  getResourcesByCategory(cat: string = ResourceCategory.General): TNTResource[] {
    var res: Array<TNTResource> = [];

    for (var i = 0; i < this._resources.length; i++) {
      if (this._resources[i].resourceCategory == cat) {
        res.push(this._resources[i]);
      }
    }

    return res;
  }
}


@Component({
  selector: 'finish-survey-dialog',
  template: `
  <div style="padding:10px;">
    <h1 mat-dialog-title>
      {{data.title}}
    </h1>
    <div mat-dialog-content>
      {{data.message}}
    </div>
    <div mat-dialog-actions>
      <button (click)="close()" align="end"  class="mat-button mat-stroked-button button" style="float:right;">
      Ok
      </button>
    </div>
  </div> 
`
})

export class FinishSurveyComponentDialog {
  constructor(public dialogRef: MatDialogRef<FinishSurveyComponentDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  close(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  title: string;
  message: string;
}
