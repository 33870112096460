import { Component, Inject } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatRadioChange } from "@angular/material/radio";
import { SurveyAPI } from "../services/surveyAPI.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';

export class TNTUser {
  userID: string | any;
  date: Date | any;
  token: string | any;
  userGroupID: string | any;
  isActive: boolean | any;
  userGroup: TNTUserGroup = new TNTUserGroup;
}

export class TNTSurvey {
  surveyID: string | any;
  createdDate: Date = new Date();
  isActive: boolean = false;
  name: string | any;
  description: string | any;
  questions: Array<TNTQuestion> = [];
  finishStatement: string | any;

}

export class TNTResponseDetail {
  responseDetailID: string | any;
  responseID: string | any;
  questionID: string | any;
  answerID: string | any;
  dateModified: Date = new Date();
  dateCreated: Date = new Date();
  sortOrder: number = 0;
  userInput: string | any;
  response: TNTResponse = new TNTResponse;
  question: TNTQuestion = new TNTQuestion;
  answer: TNTAnswer = new TNTAnswer;
}

export class TNTReportActivity {
  reportActivityID: string | any;
  responseID: string | any;
  date: Date = new Date();
  activity: string | any;
  activityType: string | any;
}

export class TNTResource {
  resourceID: string | any;
  resourceURL: string | any;
  resourceImage: string | any;
  resourceName: string | any;
  resourceDescription: string | any;
  resourceCategory: string | any;
  resourcePhone: string | any;
}

export class TNTQuestion {
  questionID: string | any;
  surveyID: string | any;
  answerSetID: string | any;
  questionText: string | any;
  instructions: string | any;
  sortOrder: number = 0;
  skipLogic: string | any;
  specialMessage: string | any;
  specialMessageLogic: string | any;
  image: string | any;
  answerSet: TNTAnswerSet = new TNTAnswerSet;
  resourceCategory: string | any;
  questionGroup: number | any;
  siblings: Array<TNTQuestion> = [];
}

export class TNTUserGroupDetail {
  userGroupDetailID: string | any;
  userGroupID: string | any;
  surveyID: string | any;
  report: boolean = false;
  sortOrder: number = 0;
  survey: TNTSurvey = new TNTSurvey;
  response: TNTResponse = new TNTResponse;
}

export class TNTUserGroup {
  userGroupID: string | any;
  name: string | any;
  date: Date = new Date();
  isActive: boolean = false;
  userGroupDetails: Array<TNTUserGroupDetail> = [];
  users: TNTUser[] = [];
}

export class TNTAnswer {
  answerSetID: string | any;
  answerID: string | any;
  answerText: string | any;
  answerScore: string | any;
  userDefined: boolean = false;
  userType: string | any;
}

export class TNTAnswerSet {
  name: string | any;
  answerSetID: string | any;
  answerType: string | any;
  answers: Array<TNTAnswer> = [];
}

export class TNTResponse {
  responseID: string | any;
  userGroupDetailID: string | any;
  userId: string | any;
  dateModified: Date = new Date();
  dateCreated: Date = new Date();
  dateReportViewed: Date = new Date(1901, 0, 1, 0, 0, 0, 0);
  responseDetails: Array<TNTResponseDetail> = [];
  user: TNTUser = new TNTUser;
  completed: boolean = false;
  reportModel: string | any;
  userGroupDetail: TNTUserGroupDetail | any;
  userGroupName: string | any;
  report: ReportModel = new ReportModel;
  reportViewedSeconds: number | any;
  get hasReport(): boolean {
    if (this.reportModel != null) {
      return true;
    }
    return false;
  }

  get currentResponseDetail(): TNTResponseDetail {
    return this.responseDetails[this.responseDetails.length - 1];
  }

  public getResponseDetailsByCategory(cat: string): Array<TNTResponseDetail> {
    var rd: Array<TNTResponseDetail> = [];
    for (var i = 0; i < this.responseDetails.length; i++) {
      if (this.responseDetails[i].question.resourceCategory == cat) {
        rd.push(this.responseDetails[i]);
      }
    }
    return rd;
  }

}

export class UserInput {
  answerID: string | any;
  value: string | any;
}

export const GroupTypeEnum = {
  Time1InterventionGroup: "Time 1 Intervention Group",
  Time1ControlGroup: "Time 1 Control Group"
};

export const ActivityType = {
  UrlClick: 'UrlClick'
};

export const AnswerType = {
  MultipleChoiceSingleAnswer: 'MultipleChoiceSingleAnswer',
  MultipleChoiceMultipleAnswer: 'MultipleChoiceMultipleAnswer',
  UserInput: 'UserInput',
  UserInputWithNA: 'UserInputWithNA'
};

export const DomainStatus = {
  Weak: 'Weak',
  Strong: 'Strong',
  None: 'None'
};

export const ResourceCategory = {
  General: 'General',
  Women: 'Women',
  POC: 'POC',
  LGBTQ: 'LGBTQ',
  LookingForWork: 'LookingForWork',
  Employed: 'Employed',
  LookingForEducation: 'LookingForEducation',
  InSchool: 'InSchool',
  CareGiving: 'CareGiving',
  FoodInsecurity: 'FoodInsecurity',
  Housing: 'Housing',
  HousingALT: 'HousingALT',
  FinancialNeeds: 'FinancialNeeds',
  Transportation: 'Transportation',
  Legal: 'Legal',
  GeneralFinancial: 'GeneralFinancial',
  PhysicalHealth: 'PhysicalHealth',
  GeneralMentalHealth: 'GeneralMentalHealth',
  GeneralMentalHealthOVA: 'GeneralMentalHealthOVA',
  GeneralMentalHealthALT: 'GeneralMentalHealthALT',
  HealthCare: 'HealthCare',
  SenseOfPurpose: 'SenseOfPurpose',
  Happiness: 'Happiness',
  Depression: 'Depression',
  SuicideIdeation: 'SuicideIdeation',
  Anxiety: 'Anxiety',
  PTSD: 'PTSD',
  AlcoholMisuse: 'AlcoholMisuse',
  HealthyEating: 'HealthyEating',
  PhysicalActivity: 'PhysicalActivity',
  Sleep: 'Sleep',
  LeisureActivity: 'LeisureActivity',
  Smoking: 'Smoking',
  SubstanceMisuse: 'SubstanceMisuse',
  BroaderSocial: 'BroaderSocial',
  IntimateRelationship: 'IntimateRelationship',

};

export class ReportModel {

  //domains
  public broaderSocialDomainStatus: string;
  public workDomainStatus: string;
  public educationDomainStatus: string;
  public caregivingDomainStatus: string;
  public financesDomainStatus: string;
  public healthBehaviorDomainStatus: string;
  public mentalHealthDomainStatus: string;
  public generalHealthDomainStatus: string;
  public intimateSocialDomainStatus: string;

  //resource sections
  public showGeneral: boolean = true; //General',
  public showWomen: boolean = false; //Women',
  public showPOC: boolean = false; //POC',
  public showLGBTQ: boolean = false; //LGBTQ',
  public showLookingForWork: boolean = false; //LookingForWork',
  public showEmployed: boolean = false; //Employed',
  public showLookingForEducation: boolean = false; //LookingForEducation',
  public showInSchool: boolean = false; //InSchool',
  public showCareGiving: boolean = false; //CareGiving',
  public showFoodInsecurity: boolean = false; //FoodInsecurity',
  public showHousing: boolean = false; //Housing',
  public showFinancialNeeds: boolean = false; //FinancialNeeds',
  public showTransportation: boolean = false; //Transportation',
  public showLegal: boolean = false; //Legal',
  public showGeneralFinancial: boolean = false; //GeneralFinancial',
  public showPhysicalHealth: boolean = false; //PhysicalHealth',
  public showGeneralMentalHealth: boolean = false; //GeneralMentalHealth',
  public showHealthCare: boolean = false; //HealthCare',
  public showSenseOfPurpose: boolean = false; //SenseOfPurpose',
  public showHappiness: boolean = false; //Happiness',
  public showDepression: boolean = false; //Depression',
  public showSuicideIdeation: boolean = false; //SuicideIdeation',
  public showAnxiety: boolean = false; //Anxiety',
  public showPTSD: boolean = false; //PTSD',
  public showAlcoholMisuse: boolean = false; //AlcoholMisuse',
  public showHealthyEating: boolean = false; //HealthyEating',
  public showPhysicalActivity: boolean = false; //PhysicalActivity',
  public showSleep: boolean = false; //Sleep',
  public showLeisureActivity: boolean = false; //LeisureActivity',
  public showSmoking: boolean = false; //Smoking',
  public showSubstanceMisuse: boolean = false; //SubstanceMisuse',
  public showBroaderSocial: boolean = false; //BroaderSocial',
  public showIntimateRelationship: boolean = false; //IntimateRelationship'

  constructor() {
    this.broaderSocialDomainStatus = DomainStatus.None;
    this.workDomainStatus = DomainStatus.None;
    this.educationDomainStatus = DomainStatus.None;
    this.caregivingDomainStatus = DomainStatus.None;
    this.financesDomainStatus = DomainStatus.None;
    this.healthBehaviorDomainStatus = DomainStatus.None;
    this.mentalHealthDomainStatus = DomainStatus.None;
    this.generalHealthDomainStatus = DomainStatus.None;
    this.intimateSocialDomainStatus = DomainStatus.None;
  }


}

export class Common {
  static dateFormat(date: Date): string {
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let ds: string = d.toString();
    let ms: string = m.toString();
    if (m < 10) {
      ms = '0' + m.toString();
    }
    if (d < 10) {
      ds = '0' + d.toString();
    }
    return date.getFullYear() + '-' + ms + '-' + ds + 'T' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
  }
}

@Component({
  selector: 'survey-dialog',
  template: `
  <div style="padding:10px;">
    <h1 mat-dialog-title>
      {{data.title}}
    </h1>
    <div mat-dialog-content>
      {{data.message}}
    </div>
    <div mat-dialog-actions>
      <button (click)="close()" align="end"  class="mat-button mat-stroked-button button" style="float:right;">
      Ok
      </button>
    </div>
  </div> 
`
})

export class SurveyComponentDialog {
  constructor(public dialogRef: MatDialogRef<SurveyComponentDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  close(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  title: string;
  message: string;
}

export class UserInformation {

  private _resources: Array<TNTResource> = [];
  private user: TNTUser = new TNTUser;
  private userGroup: TNTUserGroup = new TNTUserGroup;
  private userGroupDetails: Array<TNTUserGroupDetail> = [];
  private surveys: Array<TNTSurvey> = [];
  private responses: Array<TNTResponse> = [];
  private responseDetail: TNTResponseDetail = new TNTResponseDetail;
  private userGroupDetailIndex;
  private responseIndex;
  private questionIndex;
  private surveyIndex;
  private _userInfoLoaded: boolean = false;
  private _reportMode: boolean = false;
  private _allSurveysComplete: boolean = false;
  private _reportModel?: ReportModel;
  private _userToken: string = '';
  public loading: boolean = true;

  constructor(private sapi: SurveyAPI, public dialog: MatDialog, public router: Router) {
    this.userGroupDetailIndex = -1;
    this.responseIndex = -1;
    this.questionIndex = -1;
    this.surveyIndex = -1;
  }

  openDialog(title: string, message: string): void {
    this.dialog.open(SurveyComponentDialog, { data: { title: title, message: message } });
  }

  async getUserInformation(token: string) {
    this.loading = true;
    this._userToken = token;
    this.user = await this.sapi.getUserInfoByToken(token);
    this.responses = await this.sapi.getActiveUserResponses(this.user.userID) ?? Array<TNTResponse>();
    this.userGroup = this.user.userGroup;
    this.userGroupDetails = this.userGroup.userGroupDetails;
    
    //this.openDialog("It seems as though you may be having some thoughts of hurting yourself. This can be a common response to feeling distressed. We want you to know that help is available. We recommend that you contact your primary care provider or call the Veterans Crisis Hotline (1-800-273-8255) if you are experiencing suicidal thoughts","");


    for (var i = 0; i < this.userGroupDetails.length; i++) {
      this.surveys.push(await this.sapi.getSurvey(this.userGroupDetails[i].surveyID));

    }

    this.userGroupDetailIndex = this.surveyIndex = this.responseIndex = -1;

    //find first active survey in list that has not been completed
    for (var i = 0; i < this.userGroupDetails.length; i++) {
      let r: TNTResponse = this.userGroupDetails[i].response;
      if (r != null && r.responseID != null && this.surveys[i].isActive) {
        if (!r.completed) {
          this.userGroupDetailIndex = this.surveyIndex = this.responseIndex = i;
        } else {
          if (this.userGroupDetails[i].report) {
            if (r.reportModel == null || r.reportModel == '') {
              this.generateWellBeingReport();
            }
          }
        }
      }
    }

    //no incomplete survey found, find first survey in list that has not been started
    if (this.userGroupDetailIndex == -1) {
      for (var i = 0; i < this.userGroupDetails.length; i++) {
        let r: TNTResponse = this.userGroupDetails[i].response;
        if (r == null && this.surveys[i].isActive) {
          this.userGroupDetailIndex = this.surveyIndex = this.responseIndex = i;
         
          await this.createNewSurveyResponse(this.currentUserGroupDetail.userGroupDetailID, new Date(), this.user.userID, false);
          break;
        }
      }
    }

    //if we still have no userGroupDetailIndex at this point, then all available surveys have been completed.
    if (this.userGroupDetailIndex == -1) {
      this._allSurveysComplete = true;
    } else {
      this._allSurveysComplete = false;
      // we have a survey to finish, find correct question index to start at
      if (this.currentResponse.responseDetails != null && this.currentResponse.responseDetails.length > 0) {
        this.questionIndex = this.currentResponse.responseDetails[this.currentResponse.responseDetails.length - 1].sortOrder - 1;
      } else {
        this.questionIndex = -1;
      }
    }


    if (this._allSurveysComplete) {
      //this.userGroupDetailIndex = this.surveyIndex = this.responseIndex = this.userGroupDetails.length - 1;
      this.userGroupDetailIndex = this.surveyIndex = this.responseIndex = -1;
    }

    this._userInfoLoaded = true;
    this.loading = false;
  }

  get allSurveysCompleted(): boolean {
    return this._allSurveysComplete;
  }

  get loaded(): boolean {
    return this._userInfoLoaded;
  }

  get questionOrder(): number {
    return this.questionIndex + 1;
  }

  get currentSurvey(): TNTSurvey {
    return this.surveys[this.surveyIndex];
  }

  get currentQuestion(): TNTQuestion | any {
    if (this.surveyIndex == -1 || this.questionIndex == -1) {
      return null;
    }
    return this.surveys[this.surveyIndex].questions[this.questionIndex];
  }


  get previousResponse(): TNTResponse {
    return <TNTResponse>this.responses[this.responseIndex - 1];
  }

  get currentUserGroupDetail(): TNTUserGroupDetail {
    return this.userGroupDetails[this.userGroupDetailIndex];
  }

  get currentAnswer(): TNTAnswer {
    return this.responseDetail.answer;
  }

  set currentAnswerId(id: string) {
    this.responseDetail.answerID = id;
    this.responseDetail.answer = this.getAnswerById(id);
  }

  get currentResponse(): TNTResponse {
    return <TNTResponse>this.responses[this.responseIndex];
  }

  get surveyCompleted(): boolean {
    if (this.currentSurvey != null && this.currentSurvey.questions != null) {
      return (this.currentResponse.completed);
    } else {
      return false;
    }
  }

  async completeSurvey(): Promise<any> {
    let cr = this.currentResponse;
    cr.completed = true;
    this.responses[this.responseIndex] = cr;
    this.saveResponse();
  }

  async saveResponse(): Promise<any> {
    //save the current response ( responses[responseIndex] )
    let cr = this.currentResponse;
    cr = await this.sapi.postResponse(cr);
  }

  getQuestionBySortOrder(sortorder: number): TNTQuestion {
    for (var i = 0; i < this.currentSurvey.questions.length; i++) {
      if (this.currentSurvey.questions[i].sortOrder == sortorder) {
        return this.currentSurvey.questions[i];
      }
    }
    return new TNTQuestion;
  }

  getSurveyById(id: string): TNTSurvey {
    for (var i = 0; i < this.surveys.length; i++) {
      if (this.surveys[i].surveyID == id) {
        return this.surveys[i];
      }
    }
    return new TNTSurvey;
  }

  getAnswerById(id: string): TNTAnswer {
    for (var i = 0; i < this.currentQuestion.answerSet.answers.length; i++) {
      if (this.currentQuestion.answerSet.answers[i].answerID == id) {
        return this.currentQuestion.answerSet.answers[i];
      }
    }
    return new TNTAnswer;
  }

  async createNewSurveyResponse(userGroupDetailID: string, date: Date, userid: string, completed: boolean): Promise<TNTResponse> {

    //fix for time2, verify tht the response index is correct
    if (this.responseIndex > this.responses.length) {
      this.responses = new Array<TNTResponse>(this.responseIndex);
    }

    let newResponse: TNTResponse = new TNTResponse;
    newResponse.userGroupDetailID = userGroupDetailID;
    newResponse.dateCreated = date;
    newResponse.userId = userid;
    newResponse.completed = completed;
    newResponse = await this.sapi.postResponse(newResponse);
    this.responses.push(newResponse);
    console.log(newResponse);
    this.responseDetail = new TNTResponseDetail;
    this.responseDetail.responseID = newResponse.responseID;
    return newResponse;
  }

  async clearGroupedUserInputNA(event: MatCheckboxChange, question: TNTQuestion, answer: TNTAnswer): Promise<void> {
    if (event.checked) {

      question = this.getQuestionBySortOrder(question.sortOrder);
      let response = await this.sapi.getResponseDetails(this.currentResponse.responseID);
      response = response[0];
      let responseDetail: TNTResponseDetail = new TNTResponseDetail;

      for (var i = 0; i < response.responseDetails.length; i++) {
        if (response.responseDetails[i].sortOrder == question.sortOrder) {
          responseDetail = response.responseDetails[i];
          responseDetail.userInput = JSON.parse(responseDetail.userInput);
          break;
        }
      }

      responseDetail.answerID = (event.source._inputElement.nativeElement as HTMLInputElement).id.replace('-input', '');
      responseDetail.userInput = null;

      await this.saveResponseDetail(question, answer, this.currentResponse, responseDetail.userInput);
      if (question.sortOrder >= this.currentSurvey.questions.length) {
        this.completeSurvey().then(() => {
          console.log("Survey Completed.");
        });
      }
    }
  }

  async updateGroupedUserInputValue(event: Event, question: TNTQuestion, answer: TNTAnswer): Promise<void> {

    question = this.getQuestionBySortOrder(question.sortOrder);
    let response = await this.sapi.getResponseDetails(this.currentResponse.responseID);
    response = response[0];
    let responseDetail: TNTResponseDetail = new TNTResponseDetail;

    for (var i = 0; i < response.responseDetails.length; i++) {
      if (response.responseDetails[i].sortOrder == question.sortOrder) {
        responseDetail = response.responseDetails[i];
        responseDetail.userInput = JSON.parse(responseDetail.userInput);
        break;
      }
    }

    let newUserInput = new UserInput();
    newUserInput.answerID = (event.target as HTMLInputElement).id;
    newUserInput.value = (event.target as HTMLInputElement).value;

    if (responseDetail.userInput == null) {
      responseDetail.userInput = Array<UserInput>();
    } else {
      for (var i = 0; i < responseDetail.userInput.length; i++) {
        if ((responseDetail.userInput[i] as UserInput).answerID == (event.target as HTMLInputElement).id) {
          responseDetail.userInput[i] = newUserInput;
          await this.saveResponseDetail(question, answer, this.currentResponse, responseDetail.userInput);
          if (question.sortOrder >= this.currentSurvey.questions.length) {
            this.completeSurvey().then(() => {
              console.log("Survey Completed.");
            });
          }
          return;
        }
      }
    }

    responseDetail.userInput.push(newUserInput);
    await this.saveResponseDetail(question, answer, this.currentResponse, responseDetail.userInput);
    if (question.sortOrder >= this.currentSurvey.questions.length) {
      this.completeSurvey().then(() => {
        console.log("Survey Completed.");
      });
    }

  }

  async updateGroupedMultipleChoiceUserInputValue(event: MatCheckboxChange, question: TNTQuestion, answer: TNTAnswer): Promise<void> {

    question = this.getQuestionBySortOrder(question.sortOrder);
    let response = await this.sapi.getResponseDetails(this.currentResponse.responseID);
    response = response[0];
    let responseDetail: TNTResponseDetail = new TNTResponseDetail;

    let newUserInput = new UserInput();
    newUserInput.answerID = (event.source._inputElement.nativeElement as HTMLInputElement).id.replace('-input', '');
    newUserInput.value = event.checked;

    for (var i = 0; i < response.responseDetails.length; i++) {
      if (response.responseDetails[i].sortOrder == question.sortOrder) {
        responseDetail = response.responseDetails[i];
        responseDetail.userInput = JSON.parse(responseDetail.userInput);
        break;
      }
    }

    if (responseDetail.userInput == null) {
      responseDetail.userInput = Array<UserInput>();
    } else {
      for (var i = 0; i < responseDetail.userInput.length; i++) {
        if ((responseDetail.userInput[i] as UserInput).answerID == (event.source._inputElement.nativeElement as HTMLInputElement).id.replace('-input', '')) {

          if (!event.checked) {
            (responseDetail.userInput[i] as UserInput).value = false;
            await this.saveResponseDetail(question, answer, this.currentResponse, responseDetail.userInput);
            if (question.sortOrder >= this.currentSurvey.questions.length) {
              this.completeSurvey().then(() => {
                console.log("Survey Completed.");
              });
            }
            return;
          }

          responseDetail.userInput[i] = newUserInput;
          await this.saveResponseDetail(question, answer, this.currentResponse, responseDetail.userInput);
          if (question.sortOrder >= this.currentSurvey.questions.length) {
            this.completeSurvey().then(() => {
              console.log("Survey Completed.");
            });
          }
          return;
        }
      }
    }

    responseDetail.userInput.push(newUserInput);
    await this.saveResponseDetail(question, answer, this.currentResponse, responseDetail.userInput);
    if (question.sortOrder >= this.currentSurvey.questions.length) {
      this.completeSurvey().then(() => {
        console.log("Survey Completed.");
      });
    }
  }

  updateGroupedAnswer(event: MatRadioChange, question: TNTQuestion, answer: TNTAnswer): void {
    question = this.getQuestionBySortOrder(question.sortOrder);
    this.calculateSpecialMessage(question, answer);
    let newQuestionIndex: number = this.calculateSkipLogic(question, answer);
    this.saveResponseDetail(question, answer, this.currentResponse, null).then(() => {
      if (question.sortOrder >= question.siblings[question.siblings.length - 1].sortOrder) {


        if (question.sortOrder >= this.currentSurvey.questions.length || newQuestionIndex > this.currentSurvey.questions.length) {
          this.completeSurvey().then(() => {
            console.log("Survey Completed.");
          });
        } else {
          if (newQuestionIndex != null) {
            this.questionIndex = newQuestionIndex;
          } else {
            this.nextGroup(question);
          }
        }
      }
      if (newQuestionIndex != null) {
        this.questionIndex = newQuestionIndex;
      }
    });

  }

  calculateSkipLogic(question: TNTQuestion, answer: TNTAnswer): number | any {
    if (question.skipLogic != null) {
      let tmpFunc = Function('val', question.skipLogic);
      let retVal: number = tmpFunc(answer.answerScore) - 1;
      return retVal;
    } else {
      return null;
    }
  }

  calculateSpecialMessage(question: TNTQuestion, answer: TNTAnswer): boolean {
    if (question.specialMessage != null) {
      let tmpFunc = Function('val', question.specialMessageLogic);
      if (tmpFunc(answer.answerScore)) {
        this.openDialog(question.specialMessage, "");
        return true;
      }
    }
    return false;
  }



  async nextGroup(question: TNTQuestion): Promise<any> {
    if (this.questionIndex == -1) {
      this.questionIndex++;
    } else {
      var retVal: number = -1;
      for (var i = 0; i < this.currentSurvey.questions.length; i++) {
        if (this.currentSurvey.questions[i].questionGroup == question.questionGroup + 1) {
          this.questionIndex = i;
          window.scrollTo(0, 0);
          retVal = i;
        }
      }
      if (retVal == -1) {
        if (question.sortOrder >= this.currentSurvey.questions.length || this.questionIndex + 1 > this.currentSurvey.questions.length) {
          this.completeSurvey().then(() => {
            console.log("Survey Completed.");
          });
        }
      }
    }
  }

  async previousGroup(question: TNTQuestion): Promise<any> {
    if (this.questionIndex > -1) {
      for (var i = 0; i < this.currentSurvey.questions.length; i++) {
        if (this.currentSurvey.questions[i].questionGroup == question.questionGroup - 1) {
          this.questionIndex = i;
        }
      }
    }
  }

  async saveResponseDetail(question: TNTQuestion, answer: TNTAnswer, response: TNTResponse, userInput: any): Promise<any> {
    let responseDetail: TNTResponseDetail = new TNTResponseDetail();
    responseDetail.responseID = response.responseID;
    responseDetail.sortOrder = question.sortOrder;
    responseDetail.questionID = question.questionID;
    responseDetail.answerID = answer.answerID;
    if (userInput != null) {
      responseDetail.userInput = JSON.stringify(userInput);
    }
    console.log(responseDetail);
    await this.sapi.postResponseDetail(responseDetail);

  }


  get reportMode(): boolean {
    return this._reportMode;
  }

  async nextSurvey(): Promise<void> {

    if (this.currentUserGroupDetail.report && !this._reportMode) {
      await this.generateWellBeingReport();
      this._reportMode = true;
    } else {
      this._reportMode = false;
      if (this.userGroupDetailIndex < (this.userGroupDetails.length - 1)) {

        await this.createNewSurveyResponse(this.userGroupDetails[this.userGroupDetailIndex+1].userGroupDetailID, new Date(), this.user.userID, false);
        this.responseIndex++;
        this.userGroupDetailIndex++;
        this.surveyIndex++;
        this.questionIndex = -1;
      } else {
        document.location.href = '/';
      }
    }

  }

  async generateWellBeingReport(): Promise<void> {

    var rd: TNTResponseDetail[] = new Array<TNTResponseDetail>();
    var rm: ReportModel = new ReportModel;

    var activeResponses = await this.sapi.getActiveUserResponses(this.user.userID);

    var pRes: TNTResponse = new TNTResponse();
    try {
      pRes = await this.sapi.getResponseWithDetails(activeResponses[0].responseID);
    } catch (e) {

    }
    var cRes: TNTResponse = await this.sapi.getResponseWithDetails(activeResponses[1].responseID);
       

    var getSortOrderValue = function (r: TNTResponse, order: number): number | any {
      try {
        if (r.responseDetails[order - 1] != null && r.responseDetails[order - 1].sortOrder == order) {
          return r.responseDetails[order - 1].answer?.answerScore ?? r.responseDetails[order - 1].userInput;
        } else {
          for (var i = 0; i < r.responseDetails.length; i++) {
            if (r.responseDetails[i].sortOrder == order) {
              return r.responseDetails[i].answer.answerScore;
            }
          }
          return -1;
        }
      }
      catch (e) {
        console.log("EXCEPTION RETRIEVING VALUE FOR QUESTION " + order + " For Response ID " + r.responseID + " Exception: " + e)
      }
    };


    rm.showGeneral = true;

    //general questionaaire
    if (getSortOrderValue(pRes, 1) == 2) {
      rm.showWomen = true;
    }
    if (getSortOrderValue(pRes, 3) == 1) {
      rm.showPOC = true;
    } else {
      let ui = JSON.parse(getSortOrderValue(pRes, 4));
      rm.showPOC = true;
      if (ui != null) {
        for (var ui_inc = 0; ui_inc < ui.length; ui_inc++) {
          for (var as_inc = 0; as_inc < this.surveys[0].questions[3].answerSet.answers.length; as_inc++) {
            if (this.surveys[0].questions[3].answerSet.answers[as_inc].answerID == ui[ui_inc].answerID && this.surveys[0].questions[3].answerSet.answers[as_inc].answerScore == 0) {
              rm.showPOC = false;
            }
          }
        }
      }
    }

    if ([2, 3].includes(getSortOrderValue(pRes, 5))) {
      rm.showLGBTQ = true;
    }
    //assessment
    if (getSortOrderValue(cRes, 1) == 1) {
      rm.showLookingForWork = true;
    }

    if (getSortOrderValue(cRes, 2) <= 4 && getSortOrderValue(cRes, 2) > -1) {
      rm.showEmployed = true;
    } else {
      if (getSortOrderValue(cRes, 3) <= 4 && getSortOrderValue(cRes, 3) > -1) {
        rm.showEmployed = true;
      }
    }
    if (getSortOrderValue(cRes, 4) == 1) {
      rm.showLookingForEducation = true;
    }
    if (getSortOrderValue(cRes, 5) <= 4 && getSortOrderValue(cRes, 5) > -1) {
      rm.showInSchool = true;
    } else {
      if (getSortOrderValue(cRes, 6) <= 4 && getSortOrderValue(cRes, 6) > -1) {
        rm.showInSchool = true;
      }
    }
    if (getSortOrderValue(cRes, 8) <= 4 && getSortOrderValue(cRes, 8) > -1) {
      rm.showCareGiving = true;
    } else {
      if (getSortOrderValue(cRes, 9) <= 4 && getSortOrderValue(cRes, 9) > -1) {
        rm.showCareGiving = true;
      }
    }
    if (getSortOrderValue(cRes, 10) == 1) {
      rm.showFoodInsecurity = true;
    }
    if (getSortOrderValue(cRes, 11) == 0) {
      rm.showHousing = true;
    }
    if (getSortOrderValue(cRes, 12) == 0) {
      rm.showFinancialNeeds = true;
    }
    if (getSortOrderValue(cRes, 13) == 0) {
      rm.showTransportation = true;
    }
    if (getSortOrderValue(cRes, 14) == 1) {
      rm.showLegal = true;
    }
    if (getSortOrderValue(cRes, 15) <= 4 && getSortOrderValue(cRes, 15) > -1) {
      rm.showGeneralFinancial = true;
    }
    if (getSortOrderValue(cRes, 16) <= 4 && getSortOrderValue(cRes, 16) > -1) {
      rm.showGeneralFinancial = true;
    }
    if (getSortOrderValue(cRes, 17) == 1) {
      rm.showPhysicalHealth = true;
    }
    if (getSortOrderValue(cRes, 18) == 1) {
      rm.showGeneralMentalHealth = true;
    }
    if (getSortOrderValue(cRes, 19) == 0) {
      rm.showHealthCare = true;
    }
    if (getSortOrderValue(cRes, 20) == 0) {
      rm.showSenseOfPurpose = true;
    }
    if (getSortOrderValue(cRes, 21) == 0) {
      rm.showHappiness = true;
    }
    if ((getSortOrderValue(cRes, 24) + getSortOrderValue(cRes, 25)) >= 3) {
      rm.showDepression = true;
    }
    if ((getSortOrderValue(cRes, 26) + getSortOrderValue(cRes, 27)) >= 3) {
      rm.showAnxiety = true;
    }
    if (getSortOrderValue(cRes, 28) >= 1) {
      rm.showSuicideIdeation = true;
    }
    if ((
      getSortOrderValue(cRes, 29) +
      getSortOrderValue(cRes, 30) +
      getSortOrderValue(cRes, 31) +
      getSortOrderValue(cRes, 32)
    ) >= 5) {
      rm.showPTSD = true;
    }
    //male alcohol abuse
    if (getSortOrderValue(pRes, 1) == 1 &&
      (getSortOrderValue(cRes, 33) + getSortOrderValue(cRes, 34) + getSortOrderValue(cRes, 35)) >= 4) {
      rm.showAlcoholMisuse = true;
    }
    //female alcohol abuse
    if (getSortOrderValue(pRes, 1) == 2 &&
      (getSortOrderValue(cRes, 33) + getSortOrderValue(cRes, 34) + getSortOrderValue(cRes, 35)) >= 3) {
      rm.showAlcoholMisuse = true;
    }
    //non-binary / other alcohol abuse
    if ([0, 3, 4].includes(getSortOrderValue(pRes, 1)) &&
      (getSortOrderValue(cRes, 33) + getSortOrderValue(cRes, 34) + getSortOrderValue(cRes, 35)) >= 4) {
      rm.showAlcoholMisuse = true;
    }
    if (getSortOrderValue(cRes, 36) <= 4 && getSortOrderValue(cRes, 36) > -1) {
      rm.showHealthyEating = true;
    }
    if (getSortOrderValue(cRes, 37) <= 4 && getSortOrderValue(cRes, 37) > -1) {
      rm.showPhysicalActivity = true;
    }
    if (getSortOrderValue(cRes, 38) <= 4 && getSortOrderValue(cRes, 38) > -1) {
      rm.showSleep = true;
    }
    if (getSortOrderValue(cRes, 39) <= 4 && getSortOrderValue(cRes, 39) > -1) {
      rm.showLeisureActivity = true;
    }
    if (getSortOrderValue(cRes, 40) >= 4 && getSortOrderValue(cRes, 40) > -1) {
      rm.showSmoking = true;
    }
    if (getSortOrderValue(cRes, 41) >= 4 && getSortOrderValue(cRes, 41) > -1) {
      rm.showSubstanceMisuse = true;
    }




    if (getSortOrderValue(cRes, 42) <= 4 && getSortOrderValue(cRes, 42) > -1) {
      rm.showGeneralMentalHealth = true;
    }
    if (getSortOrderValue(cRes, 43) == 0) {
      rm.showBroaderSocial = true;
    } else {
      if (getSortOrderValue(cRes, 44) <= 4 && getSortOrderValue(cRes, 44) > -1) {
        rm.showBroaderSocial = true;
      } else {
        if (getSortOrderValue(cRes, 45) <= 4 && getSortOrderValue(cRes, 45) > -1) {
          rm.showBroaderSocial = true;
        }
      }
    }
    if (getSortOrderValue(cRes, 47) <= 4 && getSortOrderValue(cRes, 47) > -1) {
      rm.showIntimateRelationship = true;
    }
    if (getSortOrderValue(cRes, 48) <= 4 && getSortOrderValue(cRes, 48) > -1) {
      rm.showIntimateRelationship = true;
    }


    ///************************
    ///VOCATION WORK 
    ///************************

    if (getSortOrderValue(cRes, 1) == 0 &&
      getSortOrderValue(cRes, 2) > 4 &&
      getSortOrderValue(cRes, 3) > 4) {
      rm.workDomainStatus = DomainStatus.Strong;
    }

    if (getSortOrderValue(cRes, 1) == 0 &&
      (getSortOrderValue(cRes, 2) < 5 && getSortOrderValue(cRes, 2) > -1) ||
      (getSortOrderValue(cRes, 3) < 5 && getSortOrderValue(cRes, 3) > -1)) {
      rm.workDomainStatus = DomainStatus.Weak;
    }

    if (getSortOrderValue(cRes, 1) == 1) {
      rm.workDomainStatus = DomainStatus.Weak;
    }

    if (getSortOrderValue(cRes, 1) == 2) {
      rm.workDomainStatus = DomainStatus.None;
    }


    ///************************
    ///VOCATION EDUCATION
    ///************************

    if (getSortOrderValue(cRes, 4) == 0 &&
      getSortOrderValue(cRes, 5) > 4 &&
      getSortOrderValue(cRes, 6) > 4) {
      rm.educationDomainStatus = DomainStatus.Strong;
    }

    if (getSortOrderValue(cRes, 4) == 0 &&
      (getSortOrderValue(cRes, 5) < 5 && getSortOrderValue(cRes, 5) > -1) ||
      (getSortOrderValue(cRes, 6) < 5 && getSortOrderValue(cRes, 6) > -1)) {
      rm.educationDomainStatus = DomainStatus.Weak;
    }

    if (getSortOrderValue(cRes, 4) == 1) {
      rm.educationDomainStatus = DomainStatus.Weak;
    }

    if (getSortOrderValue(cRes, 4) == 2) {
      rm.educationDomainStatus = DomainStatus.None;
    }

    ///************************
    ///VOCATION Caregiving
    ///************************

    if (getSortOrderValue(cRes, 7) == 1 &&
      getSortOrderValue(cRes, 8) > 4 &&
      getSortOrderValue(cRes, 9) > 4) {
      rm.caregivingDomainStatus = DomainStatus.Strong;
    }

    if (getSortOrderValue(cRes, 7) == 1 &&
      (getSortOrderValue(cRes, 8) < 5 && getSortOrderValue(cRes, 8) > -1) ||
      (getSortOrderValue(cRes, 9) < 5 && getSortOrderValue(cRes, 9) > -1)) {
      rm.caregivingDomainStatus = DomainStatus.Weak;
    }

    if (getSortOrderValue(cRes, 7) == 2) {
      rm.caregivingDomainStatus = DomainStatus.None;
    }

    ///************************
    /// FINANCES
    ///************************

    if (!rm.showFoodInsecurity && !rm.showHousing && !rm.showFinancialNeeds && !rm.showTransportation && !rm.showLegal &&
      getSortOrderValue(cRes, 15) > 4 &&
      getSortOrderValue(cRes, 16) > 4) {
      rm.financesDomainStatus = DomainStatus.Strong;
    }

    if (rm.showFoodInsecurity || rm.showHousing || rm.showFinancialNeeds || rm.showTransportation || rm.showLegal ||
      (getSortOrderValue(cRes, 15) < 5 && getSortOrderValue(cRes, 15) > -1) ||
      (getSortOrderValue(cRes, 16) < 5 && getSortOrderValue(cRes, 16) > -1)) {
      rm.financesDomainStatus = DomainStatus.Weak;
    }


    ///************************
    /// HEALTH: HEALTH-RELATED BEHAVIORS
    ///************************

    if (!rm.showHealthyEating && !rm.showPhysicalActivity && !rm.showSleep && !rm.showLeisureActivity && !rm.showSmoking && !rm.showAlcoholMisuse && !rm.showSubstanceMisuse) {

      if (getSortOrderValue(cRes, 36) != -1 && getSortOrderValue(cRes, 37) != -1 && getSortOrderValue(cRes, 38) != -1 && getSortOrderValue(cRes, 39) != -1
        && getSortOrderValue(cRes, 40) != -1 && getSortOrderValue(cRes, 41) != -1) {
       
        rm.healthBehaviorDomainStatus = DomainStatus.Strong;
      }

    }

    if (rm.showHealthyEating || rm.showPhysicalActivity || rm.showSleep || rm.showLeisureActivity || rm.showSmoking || rm.showSubstanceMisuse || rm.showAlcoholMisuse) {
      rm.healthBehaviorDomainStatus = DomainStatus.Weak;
    }


    ///************************
    /// HEALTH: MENTAL HEALTH
    ///************************

    if (getSortOrderValue(cRes, 18) == 0 &&
      (!rm.showDepression && !rm.showSuicideIdeation && !rm.showAnxiety && !rm.showPTSD )) {
      rm.mentalHealthDomainStatus = DomainStatus.Strong;
    }

    if (getSortOrderValue(cRes, 18) == 1 ||
      (rm.showDepression || rm.showSuicideIdeation || rm.showAnxiety || rm.showPTSD )) {
      rm.mentalHealthDomainStatus = DomainStatus.Weak;
    }


    ///************************
    /// HEALTH: GENERAL HEALTH
    ///************************

    if (getSortOrderValue(cRes, 17) == 0 &&
      (!rm.showHealthCare && !rm.showSenseOfPurpose && !rm.showHappiness) &&
      getSortOrderValue(cRes, 42) > 4
    ) {
      rm.generalHealthDomainStatus = DomainStatus.Strong;
    }


    if (getSortOrderValue(cRes, 17) == 1 ||
      (rm.showHealthCare || rm.showSenseOfPurpose || rm.showHappiness) ||
      (getSortOrderValue(cRes, 42) < 5 && getSortOrderValue(cRes, 42) > -1)
    ) {
      rm.generalHealthDomainStatus = DomainStatus.Weak;
    }



    ///************************
    /// SOCIAL: BROADER SOCIAL RELATIONSHIPS
    ///************************


    if (getSortOrderValue(cRes, 43) == 1 &&
      getSortOrderValue(cRes, 44) > 4 &&
      getSortOrderValue(cRes, 45) > 4) {
      rm.broaderSocialDomainStatus = DomainStatus.Strong;
    }

    if (getSortOrderValue(cRes, 43) == 0 ||
      (getSortOrderValue(cRes, 44) < 5 && getSortOrderValue(cRes, 44) > -1) ||
      (getSortOrderValue(cRes, 45) < 5 && getSortOrderValue(cRes, 45) > -1)) {
      rm.broaderSocialDomainStatus = DomainStatus.Weak;
    }


    ///************************
    /// SOCIAL: INTIMATE RELATIONSHIP
    ///************************

    if (getSortOrderValue(cRes, 46) == 1 &&
      getSortOrderValue(cRes, 47) > 4 &&
      getSortOrderValue(cRes, 48) > 4) {
      rm.intimateSocialDomainStatus = DomainStatus.Strong;
    }

    if (getSortOrderValue(cRes, 46) == 1 &&
      ((getSortOrderValue(cRes, 47) < 5 && getSortOrderValue(cRes, 47) > -1) || (getSortOrderValue(cRes, 48) < 5 && getSortOrderValue(cRes, 48) > -1))
    ) {
      rm.intimateSocialDomainStatus = DomainStatus.Weak;
    }

    if (getSortOrderValue(cRes, 46) == 0) {
      rm.intimateSocialDomainStatus = DomainStatus.None;
    }


    /////////////////////////////////////////////

    cRes.report = rm;
    cRes.reportModel = JSON.stringify(rm);

    await this.sapi.postResponse(cRes);

    this.responses[this.responseIndex] = cRes;

    this._reportModel = rm;


    this.router.navigate(['/report/' + this.currentResponse.responseID]);
  }


}
