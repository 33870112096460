
<ng-container *ngIf="responseInfo != null">


  <mat-card class="basic-card">
    <mat-card-header style="text-align:center;">
      <mat-card-subtitle>
        <h4>{{responseInfo.userGroupDetail?.survey.name}}</h4>
      </mat-card-subtitle>
    </mat-card-header>    
    <mat-card-content style="text-align:center;" >
      <div >
        <h3 style="font-weight:bold;">Survey Response Details</h3>
        <table style="text-align:left;">
          <tr>
            <td>Survey Name:</td>
            <td>{{responseInfo.userGroupDetail?.survey.name}}</td>
          </tr>
          <tr>
            <td>User Token:</td>
            <td>{{responseInfo.user.token}}</td>
          </tr>
          <tr>
            <td>Was Survey Completed?</td>
            <td>{{responseInfo.completed}}</td>
          </tr>

          <tr>
            <td>Date Report Viewed:</td>
            <td>{{responseInfo.dateReportViewed}}</td>
          </tr>
        </table>
      </div>
      <table>
        <thead>
          <tr style="font-weight:bold;"><td>Question</td><td>Answer</td></tr>
        </thead>
        <tr *ngFor="let rd of responseInfo.responseDetails">
          <td style="text-align:left;">{{rd.question.sortOrder}}.) {{rd.question.questionText}}</td>
          <td *ngIf="rd.answer?.answerText!=null">{{rd.answer?.answerText}}</td>
          <td *ngIf="rd.userInput!=null">{{getAnswers(rd)}}</td>
        </tr>
      </table>


    </mat-card-content>
    <mat-card-footer>
      <h4><!--footer--></h4>
    </mat-card-footer>
  </mat-card>

</ng-container>
