import { HttpClient } from '@angular/common/http';
import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, UrlTree } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponseComponent } from './components/response/response.component';
import { SurveyComponent } from './components/survey/survey.component';

import { TNTResponse, UserInformation, GroupTypeEnum } from './models/models';
import { SurveyAPI } from './services/surveyAPI.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  responses: TNTResponse[];
  public token: string | any;
  public validatedToken: boolean = false;
  subscription: Subscription;
  urlTree: UrlTree = new UrlTree;
  errorMessage!: string;
  groupType: string = "";
  public groupTypeEnum = GroupTypeEnum;
  loading: boolean = false;
  
  constructor(private sapi: SurveyAPI, private route: ActivatedRoute, private router: Router) {
    
    this.responses = [];
    this.subscription = router.events.subscribe((event) => {
      this.urlTree = router.createUrlTree([],);
      if (this.token == null) {
        let re: RouterEvent = <RouterEvent>event;
        if (re.url != null) {
          switch (re.url.split("/")[1]){
            case "survey":
               this.validatedToken = true;
               this.tokenValidate(re.url.split("/")[2]);
              break;
            case "report":
              this.validatedToken = true;
              this.responseidValidate(re.url.split("/")[2]);
              break;
          }
        }
      }      
    });
  }
  ngAfterViewInit() {

  }

  async tokenValidate(value: string): Promise<void> {
    //validate the token
    value = value.trim();
    if (value != null && value.length == 8) {
      this.loading = true;
      let uInfo = await this.sapi.getUserInfoByToken(value);
      if (uInfo != null) {
        this.groupType = uInfo.userGroup.name;
        //console.log(GroupType.Time1InterventionGroup);

        this.token = value;
        this.errorMessage = "";
        this.loading = false;
      } else {
        this.loading = false;
        this.errorMessage = "The token entered is not valid.  Please verify the token entered is correct.";
      }
    } else {
      this.errorMessage = "The token entered is not valid.  Please verify the token entered is correct.";
    }
  }

  async responseidValidate(value: string): Promise<void> {
    //validate the token
    if (value != null) {
      let uInfo = await this.sapi.getUserInfoByResponseId(value);
      if (uInfo != null) {
        this.groupType = uInfo.userGroup.name;
        //console.log(GroupType.Time1InterventionGroup);

        this.token = uInfo.token;
        this.errorMessage = "";
      } else {
        this.errorMessage = "The response id entered is not valid.  Please verify the url.";
      }
    }

  }

  async menuOpened(): Promise<void> {    
    this.responses = await this.sapi.getResponseHistoryByToken(this.token,false);
    if (this.responses.length > 1) {
      this.validatedToken = true;
    }
  }

  async gotoSurvey(): Promise<void> {
    this.validatedToken = true;
    this.router.navigate(['survey/' + this.token]);
  }


  title = 'Survey';
}
