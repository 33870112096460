import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ReportComponent } from './components/report/report.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ResponseComponent } from './components/response/response.component';
import { SurveyComponent } from './components/survey/survey.component';

const routes: Routes = [

  { path: 'response/:id', component: ResponseComponent },
  { path: 'survey/:token', component: SurveyComponent },
  { path: 'report/:id', component: ReportComponent },
  { path: 'report/:id/:print', component: ReportComponent },
  { path: 'resources', component: ResourcesComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash:true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
