import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivityType, DomainStatus, ResourceCategory, TNTReportActivity, TNTResource } from '../../models/models';
import { SurveyAPI } from '../../services/surveyAPI.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  _resources = Array<TNTResource>();
  public responseID: string;
  public DomainStatus = DomainStatus;
  public ResourceCategory = ResourceCategory;
  constructor(private sapi: SurveyAPI, private route: ActivatedRoute) {
    this.responseID = "";
  }

  ngOnInit(): void {

    var id = this.route.snapshot.paramMap.get('id');
    if (id != null) {
      this.responseID = id;
    }

    this.loadResources();

  }

  async loadResources() {
    this._resources = await this.sapi.getResourcesByCategory("");
  }

  getResourcesByCategory(cat: string | any = ResourceCategory.General): TNTResource[] {
    var res: Array<TNTResource> = [];

    for (var i = 0; i < this._resources.length; i++) {
      if (cat == null || this._resources[i].resourceCategory == cat) {
        res.push(this._resources[i]);
      }
    }

    return res;
  }
  async updateResponseLog(url: string): Promise<void> {
    let ra: TNTReportActivity = new TNTReportActivity();
    ra.activityType = ActivityType.UrlClick;
    ra.activity = url;
    ra.responseID = this.responseID;
    ra = await this.sapi.postReportActivity(ra);
    console.log("Link clicked:" + url);
  }
}
