
<mat-card class="basic-card" *ngIf="responseInfo != null">
  <mat-card-header class="report-header">

    <div style="width:100%;text-align:center;">Tool Part 2: Well-Being Report and Resources</div>


  </mat-card-header>
  <mat-card-header class="report-header">


    <div style="width:100%;text-align:center;">
      <span style="color:red;">Your Personalized Well-Being Journey: Report and Resources</span>
    </div>

  </mat-card-header>
  <mat-card-actions>
    <button *ngIf="!printMode" mat-stroked-button align="end" (click)="this.print()"> Print This Report </button>

  </mat-card-actions>
  <mat-card-content class="report-body">
    <div style="width:100%;text-align:center;">
      <img src="../../../assets/images/Feedback_Report_top_road_logo.jpg" width="400" border="0">
    </div>
    <div style="text-align:center;font-size:16pt;padding:5px 20px 5px 20px;">

      <br />
      This report has been saved, and you may view it at any time by returning to this site and entering your Web Login ID.
      The menu at top right will allow you to access your saved reports and survey responses.
      You may also print this report to a printer, and if enabled on your computer, can print this report or the full list of resources to a pdf and save on your computer.
      <br />
      <br />
      <br />
      <div style="font-size:16pt;color:red;" *ngIf="(  (this.responses[0]?.userGroupName == 'Time 2 Control Group' || this.responses[0]?.userGroupName == 'Time 1 Intervention Group') && ( this.responses[2]==null || !this.responses[2]?.completed))">
        Please review your personalized well-being report. When you are done, click <u>"OPINIONS ABOUT THE TOOL"</u> at the very bottom of the report to answer a final set of questions and complete the study. Once you complete the study, you will receive information about your Amazon gift card.
        <br />
      </div>

      <div *ngIf="(responses[0]?.userGroupName == 'Time 2 Intervention Group' && !this.nextButtonClicked)" style="color:red;font-size:16pt;">
        Please review your personalized well-being report. When you are done, click "NEXT" at the very bottom of the report to complete the study and receive information about your Amazon gift card.
        <br />
      </div>
      <br />

    </div>
    <ul>
      <br />
      <br />
      <br />
      <img class="img-right" src="../../../assets/images/Feedback_Report_wb_combined_logos.png" name="Picture 55" align="right" hspace="12" width="200" border="0">

      <li>
        <p style=" margin-bottom: 0.11in">
          <font face="Roboto, 'Helvetica Neue', sans-serif">
            <font size="2" style="font-size: 11pt">
              <font size="4" style="font-size: 14pt">
                We all want to have good well-being, for example, to:
              </font>
            </font>
          </font>
        </p>
        <ul>
          <li>
            <p style=" margin-bottom: 0.11in">
              <font face="Roboto, 'Helvetica Neue', sans-serif">
                <font size="2" style="font-size: 11pt">
                  <font size="4" style="font-size: 14pt">
                    Do work that we find meaningful and rewarding (good vocational well-being
                  </font><img src="../../../assets/images/Feedback_Report_vocational_logo.png" name="Graphic 27" align="bottom" width="25" border="0">
                  <font size="4" style="font-size: 14pt">)</font>
                </font>
              </font>
            </p>
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <ul>
        <li>
          <p style=" margin-bottom: 0.11in">
            <font face="Roboto, 'Helvetica Neue', sans-serif">
              <font size="2" style="font-size: 11pt">
                <font size="4" style="font-size: 14pt">
                  Have financial security now and in the future (good financial well-being
                </font>
                <img src="../../../assets/images/Feedback_Report_financial_logo.png" name="Graphic 21" align="bottom" height="25" border="0">
                <font size="4" style="font-size: 14pt">)</font>
              </font>
            </font>
          </p>
        </li>
      </ul>
    </ul>
    <ul>
      <ul>
        <li>
          <p style=" margin-bottom: 0.11in">
            <font face="Roboto, 'Helvetica Neue', sans-serif">
              <font size="2" style="font-size: 11pt">
                <font size="4" style="font-size: 14pt">
                  Be part of a community that we enjoy (good social well-being
                </font><img src="../../../assets/images/Feedback_Report_community_logo.png" name="Graphic 6" align="bottom" height="25" border="0">
                <font size="4" style="font-size: 14pt">)</font>
              </font>
            </font>
          </p>
        </li>
      </ul>
    </ul>
    <ul>
      <ul>
        <li>
          <p style=" margin-bottom: 0.11in">
            <font face="Roboto, 'Helvetica Neue', sans-serif">
              <font size="2" style="font-size: 11pt">
                <font size="4" style="font-size: 14pt">
                  Have the best health we can (good health-related well-being
                </font><img src="../../../assets/images/Feedback_Report_health_logo.png" name="Graphic 7" align="bottom" height="25" border="0">
                <font size="4" style="font-size: 14pt">)</font>
              </font>
            </font>
          </p>
        </li>
      </ul>
    </ul>
    <br /><br /><br /><br /><br /><br />
    <img src="../../../assets/images/Feedback_Report_veterans_pic.jpg" class="img-right" name="Picture 263" align="right" hspace="12" width="200" border="0">
    <ul>
      <li>
        <p style=" margin-bottom: 0.11in">
          <font face="Roboto, 'Helvetica Neue', sans-serif">
            <font size="2" style="font-size: 11pt">
              <font size="4" style="font-size: 14pt">
                But we all experience challenges in one or more of these areas at some points in our lives.
              </font>
            </font>
          </font>
        </p>
      </li>
    </ul>
    <ul>
      <li>
        <p style=" margin-bottom: 0.11in; margin-right:20px;">
          <font face="Roboto, 'Helvetica Neue', sans-serif">
            <font size="2" style="font-size: 11pt">
              <font size="4" style="font-size: 14pt">
                As veterans, you have earned the right to many resources that can improve your <br />well-being in these areas.
              </font>
            </font>
          </font>
        </p>
      </li>
    </ul>
    <ul>
      <li>
        <p style=" margin-bottom: 0.11in; margin-right: 20px;">
          <font face="Roboto, 'Helvetica Neue', sans-serif">
            <font size="2" style="font-size: 11pt">
              <font size="4" style="font-size: 14pt">
                This report provides recommendations for resources that are focused on <br />
                areas in which you said you are experiencing lower well-being right now.
              </font>
            </font>
          </font>
        </p>
      </li>
    </ul>
    <p style=" margin-left: 0.25in; margin-bottom: 0.11in">
      <br>
      <br>
    </p>
    <br /><br />
    <img class="img-left" src="../../../assets/images/Feedback_Report_pen_graph.jpg" name="Picture 4" align="left" hspace="12" width="294" border="0"
         style="margin-right:20px;margin-top:20px;padding-right:20px;">
    <ul style="margin-right:40px;">
      <li>
        <p>
          <font size="4" style="font-size: 14pt">
            There are three sections to this report:
          </font>
        </p>
      </li>
      <li>
        <ul>
          <li>
            <p style="line-height: 108%">
              <font face="Roboto, 'Helvetica Neue', sans-serif">
                <font size="2" style="font-size: 11pt">
                  <font size="4" style="font-size: 14pt">
                    The first section describes areas in which you reported doing well (“green light” areas;
                  </font><font size="4" style="font-size: 14pt">
                  </font>
                  <img src="../../../assets/images/Feedback_Report_green_light.png" name="Picture 1" align="bottom" height="25" border="0">
                  <font size="4" style="font-size: 14pt">
                    ),
                    as well as areas that you said were not going as well (“speedbump”
                    areas;
                  </font>
                  <img src="../../../assets/images/speedbump.png" name="Graphic 258" align="bottom" height="25" border="0">
                  <font size="4" style="font-size: 14pt">)</font>
                </font>
              </font>
            </p>
          </li>
        </ul>
      </li>
    </ul>
    <ul>
      <li>
        <p style="margin-right:40px;">
          <a name="_Hlk94540952"></a>
          <font face="Roboto, 'Helvetica Neue', sans-serif">
            <font size="2" style="font-size: 11pt">
              <font size="4" style="font-size: 14pt">
                The
                second section includes recommendations for resources for areas in
                which you reported lower well-being. All are free, nationally
                available, and in many cases, can only be used by veterans like
                you. Many of these resources are open to all veterans, but you will
                need check with each to confirm your eligibility.
              </font>
            </font>
          </font>
        </p>
      </li>
      <li>
        <p style="margin-right:40px;">
          <a name="_Hlk94540952"></a>
          <font face="Roboto, 'Helvetica Neue', sans-serif">
            <font size="2" style="font-size: 11pt">
              <font size="4" style="font-size: 14pt">
                Please
                note that some non-VA organizations that provide recommended
                resources may offer additional membership-based resources that
                require paying an annual fee. Although these may be the right
                choice for some veterans, their inclusion in this report does not
                constitute endorsement of any programs, services, or support
                require payment.
              </font>
            </font>
          </font>
        </p>
      </li>
      <li>
        <p style="line-height: 108%">
          <font face="Roboto, 'Helvetica Neue', sans-serif">
            <font size="2" style="font-size: 11pt">
              <font size="4" style="font-size: 14pt">
                You can also access a full list of resources, which may be useful to you later, by clicking on the menu  <mat-icon>menu</mat-icon> icon in the upper right-hand corner of this page and selecting the resources option.
              </font>
            </font>
          </font>
        </p>
      </li>
    </ul>
  </mat-card-content>
  <br /><br /><hr /><br /><br />
  <mat-card-content class="report-body">
    <h2 align="center" style="page-break-before: always;text-align:center;">
      <font color="#000000">
        <font style="font-size: 17pt">
          <b>
            Your Personalized Well-Being Profile
          </b>
        </font>
      </font>
    </h2>
    <br /><br />
    <!--STRENGTHS-->
    <div *ngIf="((responseInfo.report?.broaderSocialDomainStatus != null) && (
                  responseInfo.report.workDomainStatus == DomainStatus.Strong ||
                  responseInfo.report.broaderSocialDomainStatus == DomainStatus.Strong ||
                  responseInfo.report.educationDomainStatus == DomainStatus.Strong ||
                  responseInfo.report.caregivingDomainStatus == DomainStatus.Strong ||
                  responseInfo.report.financesDomainStatus == DomainStatus.Strong ||
                  responseInfo.report.healthBehaviorDomainStatus == DomainStatus.Strong ||
                  responseInfo.report.mentalHealthDomainStatus == DomainStatus.Strong ||
                  responseInfo.report.generalHealthDomainStatus == DomainStatus.Strong ||
                  responseInfo.report.intimateSocialDomainStatus == DomainStatus.Strong))">
      <h2 class="western" align="center" style="text-align:center;">
        <font color="#000000">
          <font style="font-size: 17pt">
            Areas of Strength for You
          </font>
        </font>
      </h2>
      <br />

      <div style="display:flex;flex-wrap:wrap;">
        <div class="strengthitem" *ngIf="responseInfo.report.workDomainStatus == DomainStatus.Strong">
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-bottom:2px;">
            <img src="../../../assets/images/Feedback_Report_work_logo.png" name="Graphic 2" align="bottom" height="86" border="0">
          </p>
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-top:0px;height:45px;">
            <font face="Roboto, 'Helvetica Neue', sans-serif"><font size="2" style="font-size: 11pt"><font face="Calibri Light, serif"><font size="4" style="font-size: 14pt"><b>Work<br /></b></font></font></font></font>
          </p>
        </div>
        <div class="strengthitem" *ngIf="responseInfo.report.educationDomainStatus == DomainStatus.Strong">
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-bottom:2px;">
            <img src="../../../assets/images/Feedback_Report_education.png" name="Graphic 28" align="bottom" height="86" border="0">
          </p>
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-top:0px;height:45px;">
            <font face="Roboto, 'Helvetica Neue', sans-serif"><font size="2" style="font-size: 11pt"><font face="Calibri Light, serif"><font size="4" style="font-size: 14pt"><b>Education<br /></b></font></font></font></font>
          </p>
        </div>
        <div class="strengthitem" *ngIf="responseInfo.report.caregivingDomainStatus == DomainStatus.Strong">
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-bottom:2px;">
            <img src="../../../assets/images/Feedback_Report_caregiving.png" name="Graphic 4" align="bottom" height="86" border="0">
          </p>
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-top:0px;height:45px;">
            <font face="Roboto, 'Helvetica Neue', sans-serif"><font size="2" style="font-size: 11pt"><font face="Calibri Light, serif"><font size="4" style="font-size: 14pt"><b>Caregiving<br /></b></font></font></font></font>
          </p>
        </div>
        <div class="strengthitem" *ngIf="responseInfo.report.financesDomainStatus == DomainStatus.Strong">
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-bottom:2px;">
            <img src="../../../assets/images/Feedback_Report_financial_logo.png" name="Image1" align="bottom" height="86" border="0">
          </p>
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-top:0px;height:45px;">
            <font face="Roboto, 'Helvetica Neue', sans-serif"><font size="2" style="font-size: 11pt"><font face="Calibri Light, serif"><font size="4" style="font-size: 14pt"><b>Finances<br /></b></font></font></font></font>
          </p>
        </div>
        <div class="strengthitem" *ngIf="responseInfo.report.healthBehaviorDomainStatus == DomainStatus.Strong">
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-bottom:2px;">
            <img src="../../../assets/images/Feedback_Report_health_related.png" name="Graphic 24" align="bottom" height="86" border="0">
          </p>
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-top:0px;height:45px;">
            <font face="Roboto, 'Helvetica Neue', sans-serif"><font size="2" style="font-size: 11pt"><font face="Calibri Light, serif"><font size="4" style="font-size: 14pt"><b>Health-Related Behaviors</b></font></font></font></font>
          </p>
        </div>
        <div class="strengthitem" *ngIf="responseInfo.report.mentalHealthDomainStatus == DomainStatus.Strong">
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-bottom:2px;">
            <img src="../../../assets/images/Feedback_Report_mental_health.png" name="Graphic 30" align="bottom" height="86" border="0">
          </p>
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-top:0px;height:45px;">
            <font face="Roboto, 'Helvetica Neue', sans-serif"><font size="2" style="font-size: 11pt"><font face="Calibri Light, serif"><font size="4" style="font-size: 14pt"><b>Mental<br /> Health</b></font></font></font></font>
          </p>
        </div>
        <div class="strengthitem" *ngIf="responseInfo.report.generalHealthDomainStatus == DomainStatus.Strong">
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-bottom:2px;">
            <img src="../../../assets/images/Feedback_Report_general_health.png" name="Graphic 12" align="bottom" height="86" border="0">
          </p>
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-top:0px;height:45px;">
            <font face="Roboto, 'Helvetica Neue', sans-serif"><font size="2" style="font-size: 11pt"><font face="Calibri Light, serif"><font size="4" style="font-size: 14pt"><b>General<br /> Health</b></font></font></font></font>
          </p>
        </div>
        <div class="strengthitem" *ngIf="responseInfo.report.intimateSocialDomainStatus == DomainStatus.Strong">
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-bottom:2px;">
            <img src="../../../assets/images/Feedback_Report_intimate_relationships.png" name="Graphic 26" align="bottom" height="86" border="0">
          </p>
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-top:0px;height:45px;">
            <font face="Roboto, 'Helvetica Neue', sans-serif"><font size="2" style="font-size: 11pt"><font face="Calibri Light, serif"><font size="4" style="font-size: 14pt"><b>Intimate Relationship</b></font></font></font></font>
          </p>
        </div>
        <div class="strengthitem" *ngIf="responseInfo.report.broaderSocialDomainStatus == DomainStatus.Strong">
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-bottom:2px;">
            <img src="../../../assets/images/Feedback_Report_social_relationships.png" name="Graphic 33" align="bottom" height="86" border="0">
          </p>
          <p align="center" style="orphans: 2; widows: 2" style="border: 1px solid #000000; padding: 0in 0.08in;margin-top:0px;height:45px;">
            <font face="Roboto, 'Helvetica Neue', sans-serif"><font size="2" style="font-size: 11pt"><font face="Calibri Light, serif"><font size="4" style="font-size: 14pt"><b>Broader Social Relationships</b></font></font></font></font>
          </p>
        </div>
      </div>
      <div style="text-align: center; margin-bottom: 100px; padding-bottom: 20px; width: 50%; margin-left: auto; margin-right: auto; ">
        <img src="../../../assets/images/Feedback_Report_green_light_big.png" height="141" border="0" style="float:left;margin-right:40px;display:block;">
        <ul style="text-align:left;padding-top:20px;">
          <li>
            <p>
              Based on what you reported, you are doing especially well with
              <b>
                <span class="comma" *ngIf="responseInfo.report.workDomainStatus == DomainStatus.Strong">work</span>
                <span class="comma" *ngIf="responseInfo.report.educationDomainStatus == DomainStatus.Strong">education</span>
                <span class="comma" *ngIf="responseInfo.report.caregivingDomainStatus == DomainStatus.Strong">caregiving</span>
                <span class="comma" *ngIf="responseInfo.report.financesDomainStatus == DomainStatus.Strong">finances</span>
                <span class="comma" *ngIf="responseInfo.report.healthBehaviorDomainStatus == DomainStatus.Strong">health-related behaviors</span>
                <span class="comma" *ngIf="responseInfo.report.mentalHealthDomainStatus == DomainStatus.Strong">mental health</span>
                <span class="comma" *ngIf="responseInfo.report.generalHealthDomainStatus == DomainStatus.Strong">general health</span>
                <span class="comma" *ngIf="responseInfo.report.broaderSocialDomainStatus == DomainStatus.Strong">broader social relationships</span>
                <span class="comma" *ngIf="responseInfo.report.intimateSocialDomainStatus == DomainStatus.Strong">intimate relationship</span>
              </b> and may not require more help in these areas.
            </p>
          </li>
          <li>
            <p>
              It is important to recognize areas of strength in your life because
              these strengths can balance out the impact of challenges in in other
              life domains.
            </p>
          </li>
        </ul>


      </div>
    </div>
    <!--WEAKNESSES-->
    <div *ngIf="((responseInfo.report?.broaderSocialDomainStatus!=null) && (responseInfo.report.broaderSocialDomainStatus == DomainStatus.Weak ||
                  responseInfo.report.workDomainStatus == DomainStatus.Weak ||
                  responseInfo.report.educationDomainStatus == DomainStatus.Weak ||
                  responseInfo.report.caregivingDomainStatus == DomainStatus.Weak ||
                  responseInfo.report.financesDomainStatus == DomainStatus.Weak ||
                  responseInfo.report.healthBehaviorDomainStatus == DomainStatus.Weak ||
                  responseInfo.report.mentalHealthDomainStatus == DomainStatus.Weak ||
                  responseInfo.report.generalHealthDomainStatus == DomainStatus.Weak ||
                  responseInfo.report.intimateSocialDomainStatus == DomainStatus.Weak))">

      <h2 class="western" align="center" style="text-align:center;">
        <font color="#000000">
          <font style="font-size: 17pt">
            Areas in Which You Could Benefit from Additional Support
          </font>
        </font>
      </h2>
      <br />
      <div style="text-align: center; margin-bottom: 100px; padding-bottom: 20px; width: 50%; margin-left: auto; margin-right: auto; ">
        <img src="../../../assets/images/speedbump.png" height="141" border="0" style="float:left;margin-right:40px;display:block;">
        <ul style="text-align:left;padding-top:20px;">
          <li>
            <p>
              Based on what you reported in this survey, you may be interested in learning about resources to improve your
              <b>
                <span class="comma" *ngIf="responseInfo.report.workDomainStatus == DomainStatus.Weak">work</span>
                <span class="comma" *ngIf="responseInfo.report.educationDomainStatus == DomainStatus.Weak">education</span>
                <span class="comma" *ngIf="responseInfo.report.caregivingDomainStatus == DomainStatus.Weak">caregiving</span>
                <span class="comma" *ngIf="responseInfo.report.financesDomainStatus == DomainStatus.Weak">finances</span>
                <span class="comma" *ngIf="responseInfo.report.healthBehaviorDomainStatus == DomainStatus.Weak">health-related behaviors</span>
                <span class="comma" *ngIf="responseInfo.report.mentalHealthDomainStatus == DomainStatus.Weak">mental health</span>
                <span class="comma" *ngIf="responseInfo.report.generalHealthDomainStatus == DomainStatus.Weak">general health</span>
                <span class="comma" *ngIf="responseInfo.report.broaderSocialDomainStatus == DomainStatus.Weak">broader social relationships</span>
                <span class="comma" *ngIf="responseInfo.report.intimateSocialDomainStatus == DomainStatus.Weak">intimate relationship</span>
              </b>. 
            </p>
          </li>
          <li>
            <p>
              Below we provide personalized resources that you may find helpful. They are all free, nationally available, and many are specifically focused on the needs of veterans.
            </p>
          </li>
        </ul>
      </div>
      <hr />
      <br />
      <h2 align="center" style="page-break-before: always;text-align:center;">
        <font color="#000000">
          <font style="font-size: 17pt">
            <b>
              Personalized Resources
            </b>
          </font>
        </font>
      </h2>
      <br />
      <!--NORMAL-->
      <span [style.display]="printMode?'none':'block'">
        Select the tabs below to view lists of personalized resources for the domains in which you could benefit from additional support. Given your responses to the survey questions, we identified these resources as potentially helpful for you as you transition out of military service.
      </span>
      <br />
      <br />
      <mat-tab-group [style.display]="printMode?'none':'block'" >
        <mat-tab *ngIf="responseInfo.report.showFinancialNeeds || responseInfo.report.showGeneralFinancial || responseInfo.report.showLegal || responseInfo.report.showHousing || responseInfo.report.showTransportation || responseInfo.report.showFoodInsecurity">
          <ng-template mat-tab-label cdkTrapFocus>
            <img src="../../../assets/images/Feedback_Report_financial_logo.png" style="height:45px;padding-right:20px;"><br />
            FINANCIAL RESOURCES<br />
          </ng-template>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showFinancialNeeds">
            <div style="font-weight:bold;">
              <img src="../../../assets/images/Feedback_Report_financial_logo.png" style="height:60px;padding-right:20px;vertical-align:middle;">You indicated having difficulty meeting your immediate financial needs. The following organizations offer emergency financial assistance and may be able to help:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.FoodInsecurity)">

              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showGeneralFinancial">
            <div style="font-weight:bold;">

              <img src="../../../assets/images/Feedback_Report_financial_logo.png" style="height:60px;padding-right:20px;vertical-align:middle;"> You indicated having trouble managing your finances and/or being dissatisfied with your financial situation. The following free resources offer assistance with financial management skills and may be of interest to you:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralFinancial)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website: <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showLegal">
            <div style="font-weight:bold;">

              <img src="../../../assets/images/Feedback_Report_financial_logo.png" style="height:60px;padding-right:20px;vertical-align:middle;"> You indicated that you are experiencing legal problems that are difficult to manage. If you are looking for assistance with legal concerns, the resources below may be of interest to you:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Legal)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showHousing">
            <div style="font-weight:bold;">

              <img src="../../../assets/images/Feedback_Report_financial_logo.png" style="height:60px;padding-right:20px;vertical-align:middle;">You indicated that you do not have adequate housing. If you are concerned about homelessness, the following resources may be helpful:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Housing)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showHousing">
            <div style="font-weight:bold;">

              <img src="../../../assets/images/Feedback_Report_financial_logo.png" style="height:60px;padding-right:20px;vertical-align:middle;">If you are seeking assistance with buying a home or upgrading your current home, the following organizations may be helpful to you:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.HousingALT)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showTransportation">
            <div style="font-weight:bold;">

              <img src="../../../assets/images/Feedback_Report_financial_logo.png" style="height:60px;padding-right:20px;vertical-align:middle;">You indicated having difficulty accessing reliable transportation. The following resources may be able of assistance:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Transportation)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showFoodInsecurity">
            <div style="font-weight:bold;">

              <img src="../../../assets/images/Feedback_Report_financial_logo.png" style="height:60px;padding-right:20px;vertical-align:middle;">You indicated that you are concerned about having enough food for you or your family. You may be interested in these resources:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.FoodInsecurity)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="responseInfo.report.showLookingForWork || responseInfo.report.showEmployed || responseInfo.report.showLookingForEducation || responseInfo.report.showInSchool || responseInfo.report.showCareGiving">
          <ng-template mat-tab-label>
            <img src="../../../assets/images/Feedback_Report_vocational_logo.png" name="Graphic 2" align="bottom" style="height:45px;padding-right:20px;"><br />VOCATIONAL RESOURCES<br />
          </ng-template>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showLookingForWork">
            <div style="font-weight:bold;">
              <img src="../../../assets/images/Feedback_Report_work_logo.png" style="height:60px;padding-right:20px;vertical-align:middle;">You indicated that you are interested in finding a new job.  The following resources may be helpful to you in this process:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LookingForWork)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showEmployed">
            <div style="font-weight:bold;">
              <img src="../../../assets/images/Feedback_Report_work_logo.png" style="height:60px;padding-right:20px;vertical-align:middle;"> You indicated that you have had trouble functioning your best at work or were not satisfied with your work. If you would like help managing the challenges of civilian employment, the following resources may be helpful:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Employed)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showLookingForEducation">
            <div style="font-weight:bold;">
              <img src="../../../assets/images/Feedback_Report_education.png" style="height:60px;padding-right:20px;vertical-align:middle;"> You indicated that you are interested in pursuing additional education. For more information on financing and getting set up for secondary education, see the links below:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LookingForEducation)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showInSchool">
            <div style="font-weight:bold;">
              <img src="../../../assets/images/Feedback_Report_education.png" style="height:60px;padding-right:20px;vertical-align:middle;"> You indicated that you have not been functioning your best or satisfied at school. There are many programs available to veterans pursuing additional education. The following free resources may be helpful for you:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.InSchool)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <div class="subdomain" *ngIf="responseInfo.report.showCareGiving">
            <div style="font-weight:bold;">
              <img src="../../../assets/images/Feedback_Report_caregiving.png" style="height:60px;padding-right:20px;vertical-align:middle;"> You indicated experiencing challenges with your role as a caregiver. If you are looking for assistance in this area, you may be interested in the resources below:
            </div>
            <br />
            <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.CareGiving)">
              <div class="resourceimg">
                <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
              </div>
              <div class="resourcetext">
                <ul>
                  <li>
                    Resource: {{res.resourceName}} {{res.resourcePhone}}
                  </li>
                  <li>
                    Purpose: {{res.resourceDescription}}
                  </li>
                  <li>
                    Website:
                    <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </mat-tab>
        <mat-tab *ngIf="responseInfo.report.showHealthyEating || responseInfo.report.showPhysicalActivity || responseInfo.report.showLeisureActivity || responseInfo.report.showSmoking  || responseInfo.report.showSubstanceMisuse  || responseInfo.report.showSubstanceMisuse  || responseInfo.report.showSleep || responseInfo.report.showSuicideIdeation || responseInfo.report.showGeneralMentalHealth || responseInfo.report.showDepression || responseInfo.report.showAnxiety  || responseInfo.report.showPTSD || responseInfo.report.showGeneralMentalHealth || responseInfo.report.showPhysicalHealth || responseInfo.report.showHealthCare || responseInfo.report.showSenseOfPurpose || responseInfo.report.showHappiness">
          <ng-template mat-tab-label>
            <img src="../../../assets/images/Feedback_Report_general_health.png" name="Graphic 2" align="bottom" style="height:45px;padding-right:20px;"><br />HEALTH RESOURCES<br />
          </ng-template>
          <br />
          <ng-container *ngIf="responseInfo.report.showHealthyEating || responseInfo.report.showPhysicalActivity || responseInfo.report.showLeisureActivity || responseInfo.report.showSmoking  || responseInfo.report.showSubstanceMisuse  || responseInfo.report.showSubstanceMisuse  || responseInfo.report.showAlcoholMisuse ||responseInfo.report.showSleep ">

            <img src="../../../assets/images/Feedback_Report_health_related.png" style="height:60px;padding-right:20px;vertical-align:middle;">
            <h2>Health Related Behaviors</h2>
            <div class="subdomain">Based on your responses to questions about your health, we’ve identified the following free, nationally available resources, which may be helpful to you.  </div>
            <div class="subdomain" *ngIf="responseInfo.report.showHealthyEating">

              <br />
              <div style="font-weight:bold;">
                You indicated that you do not regularly eat a generally healthy diet. According to the
                <a target="_blank" href="https://www.dietaryguidelines.gov/sites/default/files/2020-12/Dietary_Guidelines_for_Americans_2020-2025.pdf#page=31">USDA Dietary Guidelines for Americans</a>, it is recommended that adults have a varied diet including vegetables, fruit, grains, particularly whole grains, dairy, protein foods, and oils. If you are interested in improving your nutrition, the following resources may be of interest to you:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.HealthyEating)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div class="subdomain" *ngIf="responseInfo.report.showPhysicalActivity">
              <br />
              <div style="font-weight:bold;">
                You indicated that you do not regularly get at least 2.5 hours of moderate-intensity aerobic exercise a week and/or do muscle-strengthening activities at least two days per week. This is what the
                <a target="_blank" href="https://health.gov/sites/default/files/2019-09/Physical_Activity_Guidelines_2nd_edition.pdf">U.S. Department of Health and Human Services Physical Activity Guidelines </a> recommends for U.S. adults. If you wish to improve your physical fitness, you may be interested in the resources below:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.PhysicalActivity)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="subdomain" *ngIf="responseInfo.report.showSleep">
              <br />
              <div style="font-weight:bold;">
                You indicated having trouble with your sleep. High quality sleep is an important aspect of physical health. The
                <a target="_blank" href="https://www.cdc.gov/sleep/about_sleep/how_much_sleep.html">CDC</a> recommends that adults get at least 7 hours of sleep per night. If you struggle to regularly get enough hours of high-quality sleep, the following resources may be of interest to you:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Sleep)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="subdomain" *ngIf="responseInfo.report.showLeisureActivity">
              <br />
              <div style="font-weight:bold;">
                You indicated that you do not spend much time engaging in leisure activities. The organizations listed below offer opportunities to engage in social and/or self-care activities that may be of interest to you.
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LeisureActivity)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="subdomain" *ngIf="responseInfo.report.showSmoking">
              <br />
              <div style="font-weight:bold;">
                You indicated that you use tobacco and/or nicotine products. If you are considering quitting or want more information about the support available to you through the VA, see the resources below:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Smoking)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>


            <div class="subdomain" *ngIf="responseInfo.report.showAlcoholMisuse">
              <br />
              <div style="font-weight:bold;">
                Based on your responses about your alcohol use, it is possible that you might benefit from cutting down on your drinking. The VA and SAMHSA offer confidential resources that can help you evaluate whether you should reduce your drinking and that can help with risky drinking if you wish to reduce your drinking, which you can learn more about by going to the webpages below:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.AlcoholMisuse)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div><br />
            </div>

            <div class="subdomain" *ngIf="responseInfo.report.showSubstanceMisuse">
              <br />
              <div style="font-weight:bold;">
                Based on your responses, it is possible that you might benefit from cutting down on your use of alcohol or other drugs (including prescription drugs).  The VA and SAMHSA offer confidential resources that can help you evaluate whether you should reduce your substance use, which you can learn more about by going to the webpages below:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.SubstanceMisuse)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </ng-container>
          <!--Mental Health-->
          <ng-container *ngIf="responseInfo.report.showSuicideIdeation || responseInfo.report.showDepression ||  responseInfo.report.showAnxiety  || responseInfo.report.showPTSD">

            <img src="../../../assets/images/Feedback_Report_mental_health.png" style="height:60px;padding-right:20px;vertical-align:middle;">
            <h2>Mental Health</h2>
            <div class="subdomain">Based on your responses to questions about your health, we’ve identified the following free, nationally available resources, which may be helpful to you.  </div>

            <br />
            <div class="subdomain" *ngIf="responseInfo.report.showSuicideIdeation">
              <div style="font-weight:bold;">
                You indicated having thoughts of death or hurting yourself. If you are in crisis or need immediate support, the following resources are available 24/7:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.SuicideIdeation)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div><br />
            </div>
            
            <div class="subdomain" *ngIf="responseInfo.report.showDepression">
              <div style="font-weight:bold;">
                You indicated that you have been feeling depressed or down. The VA provides confidential treatment for these kinds of symptoms and can determine whether you would benefit from mental health treatment. For more information about where you can go to get an assessment and available treatment options, as well as web-based resources that you can access from home, see the resources below:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Depression)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>

            <div class="subdomain" *ngIf="responseInfo.report.showAnxiety">
              <div style="font-weight:bold;">
                You indicated that you have been feeling anxious or nervous. The VA provides confidential treatment for these kinds of symptoms and can determine whether you would benefit from mental health treatment. For more information about where you can go to get an assessment and available treatment options, as well as web-based resources that you can access from home, see the resources below:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Anxiety)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>

            <div class="subdomain" *ngIf="responseInfo.report.showPTSD">
              <div style="font-weight:bold;">
                The feelings and experiences you report indicate that you may be having some stress-related symptoms. The VA provides confidential treatment for these kinds of symptoms and can determine whether you would benefit from mental health treatment. For more information about where you can go to get a PTSD assessment and available treatment options, as well as web-based resources that you can access from home, see the resources below:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.PTSD)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
            <br />
          </ng-container>
          <!--General Health-->
          <ng-container *ngIf="responseInfo.report.showGeneralMentalHealth || responseInfo.report.showPhysicalHealth || responseInfo.report.showHealthCare || responseInfo.report.showSenseOfPurpose || responseInfo.report.showHappiness">

            <img src="../../../assets/images/Feedback_Report_general_health.png" style="height:60px;padding-right:20px;vertical-align:middle;">
            <h2>General Health</h2>
            <div class="subdomain"> </div>

            <div class="subdomain" *ngIf="responseInfo.report.showGeneralMentalHealth || responseInfo.report.showPhysicalHealth">
              <br />
              <div style="font-weight:bold;">
                You indicated having a mental or physical health problem and/or not being satisfied with your health.  You may be interested in the resources below.
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.PhysicalHealth)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealth)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
            <div class="subdomain" *ngIf="responseInfo.report.showGeneralMentalHealth">
              <div style="font-weight:bold;">
                If you would rather seek health resources outside of VA, you can get information on how to access support through other organizations by going to the links below.
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealthOVA)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
            <div class="subdomain" *ngIf="responseInfo.report.showGeneralMentalHealth">
              <div style="font-weight:bold;">
                You can find information about self-help strategies to improve your health and well-being here:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealthALT)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>          
            <div class="subdomain" *ngIf="responseInfo.report.showHealthCare">
              <br />
              <div style="font-weight:bold;">
                You indicated that you did not have access to the health care you need. You may be interested in the following resources to help you connect with health care through the VA or elsewhere:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.HealthCare)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
            <div class="subdomain" *ngIf="responseInfo.report.showSenseOfPurpose">
              <div style="font-weight:bold;">
                You indicated that you do not have a strong sense of meaning and purpose right now. You may be interested in connecting with some of the following organizations, many of which also offer the opportunity to connect with other veterans.
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.SenseOfPurpose)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
            <div class="subdomain" *ngIf="responseInfo.report.showHappiness">
              <div style="font-weight:bold;">
                You indicated not feeling happy in general. The organization listed below can connect you with programs that may be used to improve your overall well-being.
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Happiness)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
              <br />
            </div>
            <br />

          </ng-container>

        </mat-tab>
        <mat-tab *ngIf="responseInfo.report.showBroaderSocial || responseInfo.report.showIntimateRelationship">
          <ng-template mat-tab-label>
            <img src="../../../assets/images/Feedback_Report_community_logo.png" name="Graphic 2" align="bottom" style="height:45px;padding-right:20px;"><br />SOCIAL RESOURCES<br />
          </ng-template>
          <!--Social Resources-->
          <ng-container *ngIf="responseInfo.report.showBroaderSocial">

            <img src="../../../assets/images/Feedback_Report_social_relationships.png" style="height:60px;padding-right:20px;vertical-align:middle;">
            <h2>Community Relationships</h2>
            <div class="subdomain"></div>
            <div class="subdomain" *ngIf="responseInfo.report.showBroaderSocial">
              <div style="font-weight:bold;">
                You indicated that you either don’t regularly spend time with others in your community or your social relationships aren’t going as well as you’d like. Given that social support is a powerful protective factor, you may be interested in building your community by connecting with some of the following resources:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.BroaderSocial)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br />

          </ng-container>
          <!--Intimate Relationships Resources-->
          <ng-container *ngIf="responseInfo.report.showIntimateRelationship">

            <img src="../../../assets/images/Feedback_Report_intimate_relationships.png" style="height:60px;padding-right:20px;vertical-align:middle;">
            <h2>Intimate Relationship</h2>
            <div class="subdomain"> </div>
            <div class="subdomain" *ngIf="responseInfo.report.showIntimateRelationship">
              <div style="font-weight:bold;">
                You indicated that you are experiencing difficulties in your intimate relationship. If you would like help navigating these interpersonal challenges, the following resources may be of interest to you:
              </div>
              <br />
              <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.IntimateRelationship)">
                <div class="resourceimg">
                  <img src="../../../assets/images/{{res.resourceImage}}" width="65" />
                </div>
                <div class="resourcetext">
                  <ul>
                    <li>
                      Resource: {{res.resourceName}} {{res.resourcePhone}}
                    </li>
                    <li>
                      Purpose: {{res.resourceDescription}}
                    </li>
                    <li>
                      Website:
                      <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <br />

          </ng-container>
        </mat-tab>
      </mat-tab-group>
      <!--PRINT-->

      <table cellpadding="3" cellspacing="3" style="width:99%;margin:0 auto;border:solid 1px black;" [style.display]="printMode?'block':'none'">
        <tr *ngIf="responseInfo.report.financesDomainStatus == DomainStatus.Weak">
          <td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">FINANCIAL RESOURCES</td>
        </tr>
        <tr *ngIf="responseInfo.report.financesDomainStatus == DomainStatus.Weak">
          <td style="border-bottom:solid 1px black;width:100px;">RESOURCE ICON</td>
          <td style="border-bottom:solid 1px black;width:200px;">RESOURCE NAME</td>
          <td style="border-bottom:solid 1px black;width:300px;">WHAT DOES THIS RESOURCE DO?</td>
          <td style="border-bottom:solid 1px black;width:150px;">VISIT THE WEBSITE</td>
          <td style="border-bottom: solid 1px black; width: 150px;">CALL</td>
        </tr>
        <ng-container *ngIf="responseInfo.report.showFinancialNeeds">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Financial Assistance Resources - You indicated having difficulty meeting your immediate financial needs. The following organizations offer emergency financial assistance and may be able to help:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.FinancialNeeds )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showGeneralFinancial">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Financial Management Resources - You indicated having trouble managing your finances and/or being dissatisfied with your financial situation. The following free resources offer assistance with financial management skills and may be of interest to you:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralFinancial )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>

        </ng-container>
        <ng-container *ngIf="responseInfo.report.showLegal">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Legal Resources - You indicated that you are experiencing legal problems that are difficult to manage. If you are looking for assistance with legal concerns, the resources below may be of interest to you:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Legal )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>

        </ng-container>
        <ng-container *ngIf="responseInfo.report.showHousing">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Homelessness Resources - You indicated that you do not have adequate housing. If you are concerned about homelessness, the following resources may be helpful:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Housing )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>

        </ng-container>
        <ng-container *ngIf="responseInfo.report.showHousing">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Assistane Buying or Upgrading a Home - If you are seeking assistance with buying a home or upgrading your current home, the following organizations may be helpful to you:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.HousingALT )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="responseInfo.report.showTransportation">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Transportation Resources - You indicated having difficulty accessing reliable transportation. The following resources may be able of assistance:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Transportation )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="responseInfo.report.showFoodInsecurity">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Food Insecurity Resources: You indicated that you are concerned about having enough food for you or your family. You may be interested in these resources:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.FoodInsecurity )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>


        <tr *ngIf="responseInfo.report.workDomainStatus == DomainStatus.Weak">
          <td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">VOCATIONAL RESOURCES</td>
        </tr>
        <tr *ngIf="responseInfo.report.workDomainStatus == DomainStatus.Weak">
          <td style="border-bottom:solid 1px black;">RESOURCE ICON</td>
          <td style="border-bottom:solid 1px black;">RESOURCE NAME</td>
          <td style="border-bottom:solid 1px black;">WHAT DOES THIS RESOURCE DO?</td>
          <td style="border-bottom:solid 1px black;">VISIT THE WEBSITE</td>
          <td style="border-bottom:solid 1px black;">CALL</td>
        </tr>


        <ng-container *ngIf="responseInfo.report.showLookingForWork">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">WORK RESOURCES</td>
          </tr>
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Job Search Resources - You indicated that you are interested in finding a new job.  The following resources may be helpful to you in this process:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LookingForWork )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showEmployed">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Work Functioning Resources -  You indicated that you have had trouble functioning your best at work or were not satisfied with your work. If you would like help managing the challenges of civilian employment, the following resources may be helpful:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Employed )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showLookingForEducation">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">EDUCATION RESOURCES - You indicated that you are interested in pursuing additional education. For more information on financing and getting set up for secondary education, see the links below:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LookingForEducation )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showInSchool">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">IN SCHOOL RESOURCES - You indicated that you have not been functioning your best or satisfied at school. There are many programs available to veterans pursuing additional education. The following free resources may be helpful for you:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.InSchool )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="responseInfo.report.showCareGiving">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">CAREGIVING RESOURCES - You indicated experiencing challenges with your role as a caregiver. If you are looking for assistance in this area, you may be interested in the resources below:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.CareGiving )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>


        <tr *ngIf="responseInfo.report.showHealthyEating || responseInfo.report.showPhysicalActivity || responseInfo.report.showLeisureActivity || responseInfo.report.showSmoking  ||  responseInfo.report.showSubstanceMisuse  || responseInfo.report.showSubstanceMisuse  || responseInfo.report.showSleep ">
          <td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">HEALTH RESOURCES</td>
        </tr>
        <tr *ngIf="responseInfo.report.showHealthyEating || responseInfo.report.showPhysicalActivity || responseInfo.report.showLeisureActivity || responseInfo.report.showSmoking  ||  responseInfo.report.showSubstanceMisuse  || responseInfo.report.showSubstanceMisuse  || responseInfo.report.showSleep ">
          <td style="border-bottom:solid 1px black;">RESOURCE ICON</td>
          <td style="border-bottom:solid 1px black;">RESOURCE NAME</td>
          <td style="border-bottom:solid 1px black;">WHAT DOES THIS RESOURCE DO?</td>
          <td style="border-bottom:solid 1px black;">VISIT THE WEBSITE</td>
          <td style="border-bottom:solid 1px black;">CALL</td>
        </tr>
        <tr>
          <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">HEALTH RELATED BEHAVIORS</td>
        </tr>

        <ng-container *ngIf="responseInfo.report.showHealthyEating">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">
              You indicated that you do not regularly eat a generally healthy diet. According to the
              <a target="_blank" href="https://www.dietaryguidelines.gov/sites/default/files/2020-12/Dietary_Guidelines_for_Americans_2020-2025.pdf#page=31">USDA Dietary Guidelines for Americans</a>, it is recommended that adults have a varied diet including vegetables, fruit, grains, particularly whole grains, dairy, protein foods, and oils. If you are interested in improving your nutrition, the following resources may be of interest to you:
            </td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.HealthyEating )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showPhysicalActivity">

          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">
              You indicated that you do not regularly get at least 2.5 hours of moderate-intensity aerobic exercise a week and/or do muscle-strengthening activities at least two days per week. This is what the
              <a target="_blank" href="https://health.gov/sites/default/files/2019-09/Physical_Activity_Guidelines_2nd_edition.pdf">U.S. Department of Health and Human Services Physical Activity Guidelines </a> recommends for U.S. adults. If you wish to improve your physical fitness, you may be interested in the resources below:
            </td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.PhysicalActivity )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>

        </ng-container>

        <ng-container *ngIf="responseInfo.report.showSleep">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">
              You indicated having trouble with your sleep. High quality sleep is an important aspect of physical health. The
              <a target="_blank" href="https://www.cdc.gov/sleep/about_sleep/how_much_sleep.html">CDC</a> recommends that adults get at least 7 hours of sleep per night. If you struggle to regularly get enough hours of high-quality sleep, the following resources may be of interest to you:
            </td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Sleep )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showLeisureActivity">

          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Leisure Resources - You indicated that you do not spend much time engaging in leisure activities. The organizations listed below offer opportunities to engage in social and/or self-care activities that may be of interest to you.</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LeisureActivity )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>


        <ng-container *ngIf="responseInfo.report.showSmoking">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Smoking Cessation Resources - You indicated that you use tobacco and/or nicotine products. If you are considering quitting or want more information about the support available to you through the VA, see the resources below:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Smoking )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showSubstanceMisuse">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Substance Use Resources - Based on your responses, it is possible that you might benefit from cutting down on your substance use. The VA and SAMHSA offer confidential resources that can help you evaluate whether you should reduce your substance use, which you can learn more about by going to the webpages below:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.SubstanceMisuse )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>


        <tr *ngIf="responseInfo.report.showSuicideIdeation || responseInfo.report.showGeneralMentalHealth || responseInfo.report.showAlcoholMisuse || responseInfo.report.showDepression || responseInfo.report.showAnxiety  || responseInfo.report.showPTSD">
          <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">MENTAL HEALTH</td>
        </tr>

        <ng-container *ngIf="responseInfo.report.showSuicideIdeation">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Thoughts of Suicide -  You indicated having thoughts of death or hurting yourself. If you are in crisis or need immediate support, the following resources are available 24/7:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.SuicideIdeation )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>


        <ng-container *ngIf="responseInfo.report.showAlcoholMisuse">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Alcohol Use Resources -  Based on your responses about your alcohol use, it is possible that you might benefit from cutting down on your drinking. The VA and SAMHSA offer confidential resources that can help you evaluate whether you should reduce your drinking and that can help with risky drinking if you wish to reduce your drinking, which you can learn more about by going to the webpages below:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.AlcoholMisuse )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>


        <ng-container *ngIf="responseInfo.report.showDepression">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Depression Resources - You indicated that you have been feeling depressed or down. The VA provides confidential treatment for these kinds of symptoms and can determine whether you would benefit from mental health treatment. For more information about where you can go to get an assessment and available treatment options, as well as web-based resources that you can access from home, see the resources below:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Depression )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>

        </ng-container>

        <ng-container *ngIf="responseInfo.report.showAnxiety">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Anxiety Resources -  You indicated that you have been feeling anxious or nervous. The VA provides confidential treatment for these kinds of symptoms and can determine whether you would benefit from mental health treatment. For more information about where you can go to get an assessment and available treatment options, as well as web-based resources that you can access from home, see the resources below:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Anxiety )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showPTSD">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">PTSD Resources - The feelings and experiences you report indicate that you may be having some stress-related symptoms. The VA provides confidential treatment for these kinds of symptoms and can determine whether you would benefit from mental health treatment. For more information about where you can go to get a PTSD assessment and available treatment options, as well as web-based resources that you can access from home, see the resources below:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.PTSD )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>


        <tr *ngIf="responseInfo.report.showGeneralMentalHealth || responseInfo.report.showPhysicalHealth || responseInfo.report.showHealthCare || responseInfo.report.showSenseOfPurpose || responseInfo.report.showHappiness">
          <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">GENERAL HEALTH</td>
        </tr>

        <ng-container *ngIf="responseInfo.report.showGeneralMentalHealth">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Physical or Mental Health Care Resources -  You indicated having a mental or physical health problem and/or not being satisfied with your health.  . If you are interested in using VA health care, the website below can help you find a VA Medical Center near you:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealth )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>

          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Health Resources Outside of VA -  If you would rather seek health resources outside of VA, you can get information on how to access support through other organizations by going to the links below.</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealthOVA )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Self-Help Mental Health Resources - You can find information about self-help strategies to improve your health and well-being here:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.GeneralMentalHealthALT )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showHealthCare">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Health Care Resources -   You indicated that you did not have access to the health care you need. You may be interested in the following resources to help you connect with health care through the VA or elsewhere:</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.HealthCare)">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showHealthCare">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;">Connection-Focused Resources - You indicated that you do not have a strong sense of meaning and purpose right now. You may be interested in connecting with some of the following organizations, many of which also offer the opportunity to connect with other veterans.</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.SenseOfPurpose )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>

        <ng-container *ngIf="responseInfo.report.showHappiness">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-weight:bold;page-break-before:always;"> You indicated not feeling happy in general. The organization listed below can connect you with programs that may be used to improve your overall well-being.</td>
          </tr>
          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Happiness )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>


        <tr>
          <td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">SOCIAL RESOURCES</td>
        </tr>
        <tr>
          <td style="border-bottom:solid 1px black;">RESOURCE ICON</td>
          <td style="border-bottom:solid 1px black;">RESOURCE NAME</td>
          <td style="border-bottom:solid 1px black;">WHAT DOES THIS RESOURCE DO?</td>
          <td style="border-bottom:solid 1px black;">VISIT THE WEBSITE</td>
          <td style="border-bottom:solid 1px black;">CALL</td>
        </tr>

        <ng-container *ngIf="responseInfo.report.showBroaderSocial">
          <tr *ngIf="responseInfo.report.showBroaderSocial">
            <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">COMMUNITY RELATIONSHIP RESOURCES - You indicated that you either don’t regularly spend time with others in your community or your social relationships aren’t going as well as you’d like. Given that social support is a powerful protective factor, you may be interested in building your community by connecting with some of the following resources:</td>
          </tr>

          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.BroaderSocial )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>


        <ng-container *ngIf="responseInfo.report.showIntimateRelationship">
          <tr>
            <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">INTIMATE RELATIONSHIP RESOURCES - You indicated that you are experiencing difficulties in your intimate relationship. If you would like help navigating these interpersonal challenges, the following resources may be of interest to you:</td>
          </tr>

          <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.IntimateRelationship )">
            <td class="resourceimg">
              <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
            </td>
            <td>
              {{res.resourceName}}
            </td>
            <td>
              {{res.resourceDescription}}
            </td>
            <td>
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </td>
            <td>
              {{res.resourcePhone}}
            </td>
          </tr>
        </ng-container>




      </table>




    </div>
    <hr />
    <br />
    <h2 align="center" style="page-break-before: always;text-align:center;">
      <font color="#000000">
        <font style="font-size: 17pt">
          <b>
            Resources All Veterans May Find Useful
          </b>
        </font>
      </font>
    </h2>
    <br />
    <div>In addition to the personalized resources provided in this report, you may be interested in the following resources, which may be helpful to you in your transition out of military service. Select the tabs below to view general resources, or resources available to veterans who identify as female, a racial/ethnic minority, or a member of the LGBTQ+ community. </div>
    <br />
    <!--NORMAL-->
    <mat-tab-group [style.display]="printMode?'none':'block'">
      <mat-tab>
        <ng-template mat-tab-label>GENERAL RESOURCES</ng-template>

        <h2>General Resources</h2>
        <br />
        <div>
          <b>You may be interested in the following general resources, which may be helpful to you in your transition out of military service.</b>
        </div>
        <br />
        <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.General )">
        <td class="resourceimg">
          <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
        </td>
        <div class="resourcetext">
          <ul>
            <li>
              Resource: {{res.resourceName}} {{res.resourcePhone}}
            </li>
            <li>
              Purpose: {{res.resourceDescription}}
            </li>
            <li>
              Website:
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </li>
          </ul>
        </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>GENDER/RACE/SEXUAL IDENTITY RESOURCES</ng-template>

        <h2>Gender/Race/Sexual Identity Resources</h2>
        <br />
        <div>
          <b>The following resources available to veterans who identify as female, a racial/ethnic minority, or a member of the LGBTQ+ community.</b>
        </div>
        <br />
        <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LGBTQ )">
        <td class="resourceimg">
          <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
        </td>
        <div class="resourcetext">
          <ul>
            <li>
              Resource: {{res.resourceName}} {{res.resourcePhone}}
            </li>
            <li>
              Purpose: {{res.resourceDescription}}
            </li>
            <li>
              Website:
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </li>
          </ul>
        </div>
        </div>
        <br />
        <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Women )">
        <td class="resourceimg">
          <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
        </td>
        <div class="resourcetext">
          <ul>
            <li>
              Resource: {{res.resourceName}} {{res.resourcePhone}}
            </li>
            <li>
              Purpose: {{res.resourceDescription}}
            </li>
            <li>
              Website:
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </li>
          </ul>
        </div>
        </div>
        <br />
        <div class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.POC )">
        <td class="resourceimg">
          <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
        </td>
        <div class="resourcetext">
          <ul>
            <li>
              Resource: {{res.resourceName}} {{res.resourcePhone}}
            </li>
            <li>
              Purpose: {{res.resourceDescription}}
            </li>
            <li>
              Website:
              <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
            </li>
          </ul>
        </div>
        </div>
      </mat-tab>
    </mat-tab-group>
    <!--PRINT-->

    <table cellpadding="3" cellspacing="3" style="width:99%;margin:0 auto;border:solid 1px black;" [style.display]="printMode?'block':'none'">
      <tr>
        <td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">GENERAL RESOURCES</td>
      </tr>
      <tr>
        <td style="border-bottom:solid 1px black;width:100px;">RESOURCE ICON</td>
        <td style="border-bottom:solid 1px black;width:200px;">RESOURCE NAME</td>
        <td style="border-bottom:solid 1px black;width:300px;">WHAT DOES THIS RESOURCE DO?</td>
        <td style="border-bottom:solid 1px black;width:150px;">VISIT THE WEBSITE</td>
        <td style="border-bottom: solid 1px black; width: 150px;">CALL</td>
      </tr>
      <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.General )">
        <td class="resourceimg">
          <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
        </td>
        <td>
          {{res.resourceName}}
        </td>
        <td>
          {{res.resourceDescription}}
        </td>
        <td>
          <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
        </td>
        <td>
          {{res.resourcePhone}}
        </td>
      </tr>
      <tr>
        <td colspan="5" style="border:solid 1px black;font-size:16pt;font-weight:bold;background-color:#cdcdcd">GENDER/RACE/SEXUAL IDENTITY RESOURCES</td>
      </tr>
      <tr>
        <td style="border-bottom:solid 1px black;">RESOURCE ICON</td>
        <td style="border-bottom:solid 1px black;">RESOURCE NAME</td>
        <td style="border-bottom:solid 1px black;">WHAT DOES THIS RESOURCE DO?</td>
        <td style="border-bottom:solid 1px black;">VISIT THE WEBSITE</td>
        <td style="border-bottom:solid 1px black;">CALL</td>
      </tr>
      <tr>
        <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">RESOURCES FOR WOMEN VETERANS</td>
      </tr>
      <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.Women )">
        <td class="resourceimg">
          <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
        </td>
        <td>
          {{res.resourceName}}
        </td>
        <td>
          {{res.resourceDescription}}
        </td>
        <td>
          <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
        </td>
        <td>
          {{res.resourcePhone}}
        </td>
      </tr>
      <tr>
        <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">RESOURCES FOR MINORITY VETERANS</td>
      </tr>
      <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.POC )">
        <td class="resourceimg">
          <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
        </td>
        <td>
          {{res.resourceName}}
        </td>
        <td>
          {{res.resourceDescription}}
        </td>
        <td>
          <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
        </td>
        <td>
          {{res.resourcePhone}}
        </td>
      </tr>
      <tr>
        <td colspan="5" style="border:solid 1px black;font-size:14pt;font-weight:bold;">RESOURCES FOR LGBTQ+ VETERANS</td>
      </tr>
      <tr class="resourceitem" *ngFor="let res of getResourcesByCategory(ResourceCategory.LGBTQ )">
        <td class="resourceimg">
          <img src="../../../assets/images/{{res.resourceImage}}" width="60" />
        </td>
        <td>
          {{res.resourceName}}
        </td>
        <td>
          {{res.resourceDescription}}
        </td>
        <td>
          <a href="{{res.resourceURL}}" target="_blank" (click)="updateResponseLog(res.resourceURL)"> {{res.resourceURL}} </a>
        </td>
        <td>
          {{res.resourcePhone}}
        </td>
      </tr>
    </table>


  </mat-card-content>
  <br />
  <mat-card-content class="report-body">
    <h2 align="center" style="page-break-before: always;text-align:center;">
      <font color="#000000">
        <font style="font-size: 17pt">
          <b>
            Full List of Resources
          </b>
        </font>
      </font>
    </h2>
    <br />
    <div>
      You also have access to the full list of resources for all domains in case you would like to revisit this information later.
      <a target="_blank" routerLink="/resources" style="color:blue;">Click here</a> to view the resources for all domains.
    </div>
    <br /><br />
  </mat-card-content>
  <br />
  <mat-card-actions>
  </mat-card-actions>
  <mat-card-footer>
    <div style="width:100%;text-align:center;font-size:15pt;font-weight:bold" *ngIf="( this.responses[0]?.userGroupName!=null &&  (this.responses[0].userGroupName == 'Time 2 Control Group' || this.responses[0].userGroupName == 'Time 1 Intervention Group') && ( this.responses[2]==null || !this.responses[2]?.completed))">
      <span style="color:red;">
        Please review your personalized well-being report.  When you are done, click "OPINIONS ABOUT THE TOOL" to answer a final set of questions and complete the study.  Once you complete the study, you wil receive information about your Amazon gift card.
      </span>
      <br />
      <br />
      <button mat-stroked-button align="end" (click)="gotoFollowUp()"> OPINIONS ABOUT THE TOOL </button>
      <br />
      <br />
    </div>
    <div style="width:100%;text-align:center;font-size:15pt;font-weight:bold" *ngIf="( this.responses[0]?.userGroupName!=null && this.responses[0].userGroupName == 'Time 2 Intervention Group' && !this.nextButtonClicked)">
      <span style="color:red;">
        Please review your personalized well-being report. When you are done, click "NEXT" to complete the study and receive information about your Amazon gift card.
      </span>
      <br />
      <br />
      <button mat-stroked-button align="end" (click)="finishSurveyDialog()"> NEXT </button>
      <br />
      <br />
    </div>

  </mat-card-footer>
</mat-card>
