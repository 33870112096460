import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TNTAnswer, TNTResponse, TNTResponseDetail } from '../../models/models';
import { SurveyAPI } from '../../services/surveyAPI.service';

@Component({
  selector: 'app-response',
  templateUrl: './response.component.html',
  styleUrls: ['./response.component.css']
})
export class ResponseComponent implements OnInit {
  

  responseInfo?: TNTResponse;


  async loadResponseDetail(responseID: string | any): Promise<void> {

    this.responseInfo = await this.sapi.getResponseWithDetails(responseID);
    
  }


  constructor(private sapi: SurveyAPI, private route: ActivatedRoute) {


   
  }

  getAnswers(rd: TNTResponseDetail): string {
    let answers: string = "";

    let ui = JSON.parse(rd.userInput);

    for (var ui_inc = 0; ui_inc < ui.length; ui_inc++) {
      for (var as_inc = 0; as_inc < rd.question.answerSet?.answers.length; as_inc++) {
        if (rd.question.answerSet?.answers[as_inc].answerID == ui[ui_inc].answerID) {
          if (rd.question.answerSet?.answers[as_inc].userDefined == true) {
            answers += ui[ui_inc].value + " ,";
          } else {
            answers += rd.question.answerSet?.answers[as_inc].answerText + ", ";
          }
        }
      }
    }      

    return answers;
  }


  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      var id = this.route.snapshot.paramMap.get('id');
      this.loadResponseDetail(id);
    });
  }

}
