import { Injectable } from '@angular/core';
import { catchError, Observable, of, map, tap } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import {
  TNTUser, TNTResponseDetail, TNTAnswer, TNTAnswerSet, TNTUserGroupDetail,
  TNTUserGroup, TNTQuestion, TNTResource, TNTResponse, TNTSurvey, TNTReportActivity
} from '../models/models';


@Injectable({
  providedIn: 'root'
})
export class SurveyAPI {

  private url: string = '/api';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json',  })
  };

  constructor(private http: HttpClient) { }

  public getUserInfoByToken(token: string): Promise<TNTUser|any> {   
    return this.http.get<TNTUser>(this.url + '/getuserinfobytoken?token=' + token, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public getUserInfoByResponseId(responseid: string): Promise<TNTUser | any> {
    return this.http.get<TNTUser>(this.url + '/getuserinfobyresponseid?responseid=' + responseid, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }
  public getSurvey(surveyid: string): Promise<TNTSurvey | any> {   
    return this.http.get<TNTSurvey>(this.url + '/getsurvey?surveyid=' + surveyid, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public getResponseDetails(responseid: string): Promise<TNTResponseDetail[] | any> {   
    return this.http.get<TNTResponseDetail[]>(this.url + '/getresponsedetails?responseid=' + responseid, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public getUserResponses(userid: string): Promise<TNTResponse[]|any> {    
    return this.http.get<TNTResponse[]>(this.url + '/getuserresponses?userid=' + userid, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public getActiveUserResponses(userid: string): Promise<TNTResponse[] | any> {
    return this.http.get<TNTResponse[]>(this.url + '/getactiveuserresponses?userid=' + userid, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public postResponseDetail(responseDetail: TNTResponseDetail): Promise<TNTResponseDetail|any> {   
    return this.http.post<TNTResponseDetail>(this.url + '/postresponsedetail', responseDetail, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public postResponse(response: TNTResponse): Promise<TNTResponse|any> {   
    return this.http.post<TNTResponse>(this.url + '/postresponse', response, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public getResourcesByCategory(resourceCategory: string): Promise<TNTResource[] | any> {
    return this.http.get<TNTResource[]>(this.url + '/getresourcesbycategory?resourcecategory=' + resourceCategory, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public getResource(resourceId: string): Promise<TNTResource[] | any> {
    return this.http.get<TNTResource[]>(this.url + '/getresource?resourceid=' + resourceId, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public getResponseHistoryByToken(token: string, onlyActive: boolean): Promise<TNTUser | any> {
    return this.http.get<TNTUser>(this.url + '/responsehistorybytoken?token=' + token + '&onlyActive=' + onlyActive, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public getResponseWithDetails(responseid: string): Promise<TNTResponse | any> {
    return this.http.get<TNTResponse>(this.url + '/getresponsewithdetails?responseid=' + responseid, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

  public postReportActivity(response: TNTReportActivity): Promise<TNTReportActivity | any> {
    return this.http.post<TNTReportActivity>(this.url + '/postreportactivity', response, this.httpOptions).toPromise().catch((err: HttpErrorResponse) => { console.log(err); });
  }

}
