<script src="models.ts"></script>
<mat-toolbar color="primary">
  <mat-toolbar-row class="title-toolbar">
    <div class="logodiv"><img src="../assets/images/logo_sm.png" class="vaimg" /></div>
    <div class="sitetitlediv">
      <ng-container *ngIf="!validatedToken">
        <a routerLink="/">VA Well Being Assessment</a>
      </ng-container>
      <ng-container *ngIf="validatedToken">
        <a routerLink="/survey/{{token}}">VA Well Being Assessment</a>
      </ng-container>
    </div>

    <div class="menubtndiv">

      <button mat-icon-button class="menubtn" [matMenuTriggerFor]="menu"
              (menuOpened)="menuOpened()">
        <mat-icon>menu</mat-icon>
      </button>

    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="reportMenu" *ngIf="token != null && token !== '' ">
        <mat-icon class="material-icons-outlined">analytics</mat-icon>
        <span>My Reports</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="responsesMenu" *ngIf="token != null && token !== '' ">
        <mat-icon>fact_check</mat-icon>
        <span>My Survey Responses</span>
      </button>
      <button mat-menu-item [matMenuTriggerFor]="resourceMenu" *ngIf="groupType == groupTypeEnum.Time1InterventionGroup">
        <mat-icon>fact_check</mat-icon>
        <span>Resources</span>
      </button>
    </mat-menu>

    <mat-menu #responsesMenu="matMenu">
      <ng-container *ngFor="let r of responses">
        <button mat-menu-item *ngIf="r.completed || !r.completed" routerLink="/response/{{r.responseID}}">
          <span style="margin-right:20px;">{{r.dateCreated | date}}</span>
          <mat-icon [ngStyle]="{'color':'green'}" *ngIf="r.completed">check_circle_outline</mat-icon>
          <mat-icon [ngStyle]="{'color':'red'}" *ngIf="!r.completed">highlight_off</mat-icon>
        </button>
      </ng-container>
    </mat-menu>

    <mat-menu #reportMenu="matMenu">
      <ng-container *ngFor="let r of responses">
        <button mat-menu-item *ngIf="r.reportModel !== null" routerLink="/report/{{r.responseID}}">
          <span style="margin-right:20px;">{{r.dateCreated | date}}</span>
        </button>
      </ng-container>
    </mat-menu>

    <mat-menu #resourceMenu="matMenu">
      <button mat-menu-item routerLink="/resources">
        <span style="margin-right:20px;">All Resources</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<div *ngIf="token == null && !validatedToken && this.urlTree.root != null && this.urlTree.root.children['primary']?.segments == null" class="center">

  <h1 style="text-align:center;">Please enter the unique Web login ID provided in the mailing to proceed to the next page where you can learn more about the study and decide if you would like to participate.</h1>
  <input [ngModel]="token" (ngModelChange)="tokenValidate($event)" placeholder="Web Login ID" style="width:200px;margin-left:auto;margin-right:auto;">
  <div id="errorMessage" style="padding:20px;color:red;">{{errorMessage}}</div>
  <ng-container *ngIf="loading">
    <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
  </ng-container>
</div>

<div *ngIf="validatedToken==false && token!=null" class="center">

  <div class=WordSection1>

    <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;'>
      <b style=''>
        <span style='font-size:14pt;'>
          Study Information and Consent to
          Participate at Time 2
        </span>
      </b>
    </p>

    <button id="printbtn" color="primary" style="font-size:12pt;" mat-button onclick="printbtn.style.display = 'none'; window.print();">Print This Page</button>
    <p class=MsoNoSpacing style=''>
      <b><span style=''></span></b>
    </p>

    <p class=MsoNoSpacing style=''>
      <b>
        <span style=''>
          PLEASE SCROLL DOWN TO

          LEARN ABOUT THE FOLLOW-UP PORTION OF THIS STUDY AND CONSENT TO PARTICIPATE. ONCE YOU SELECT THE CONSENT
          BUTTON YOU WILL BE TAKEN TO THE FOLLOW-UP SURVEY.
        </span>
      </b>
    </p>

    <p class=MsoNoSpacing style=''>
      <b><span style=''></span></b>
    </p>

    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:0in;margin-top:0in;'>
      <b style=''>
        <span style=''>
          Why am I being invited to complete this study's follow-up survey?
        </span>
      </b>
    </p>

    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:0in;margin-top:0in;'>
      <span style=''>
        About 3 months ago, you agreed to participate in this study and completed the first online survey.
        We would now like to invite you to complete the follow-up online survey. While we hope that you complete
        the follow-up survey, you are not obligated to do so.
      </span>
    </p>

    <p class=MsoNoSpacing>
      <b style=''>
        <span style=''></span>
      </b>
    </p>


    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:0in;margin-top:0in;'>
      <b style=''>
        <span style=''>
          What is this study about?
        </span>
      </b>
    </p>

    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:0in;margin-top:0in;'>
      <span style=''>
        The Measurement-Based Transition Assistance (MBTA) Study will evaluate an online tool
        created for veterans who have transitioned from the military to civilian life within the
        past two years, though, we may offer this tool to all veterans in the future. The purpose
        of the online tool is to help veterans improve their well-being in different aspects of their
        lives as they transition from military to civilian life and to connect them with relevant supports.
        The tool focuses on four well-being related domains: vocation (i.e., employment, education,
        and caregiving), finances, health (i.e., physical and mental health), and social well-being.
      </span>
    </p>

    <p class=MsoNoSpacing>
      <b style=''>
        <span style=''></span>
      </b>
    </p>

    <p class=MsoNoSpacing style=''>
      <b style=''>
        <span style=''>
          Who are the collaborators on this study?
        </span>
      </b>
      <span style=''>
        <br> This
        study is being funded and conducted by the VA. The online tool and study surveys
        are being hosted by <span class=SpellE>ACESFed</span>, on behalf of VA. Dynamic
        Integrated Services/IPSOS is assisting with mailing of study invitations and
        reminders on behalf of VA.
      </span>
    </p>

    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:0in;margin-top:0in;'>
      <b style=''>
        <span style=''>
          What
          does this study involve?
        </span>
      </b>
    </p>

    <p class=MsoNormal style='margin-bottom:0in;margin-bottom:0in;margin-top:0in;'>
      <span style=''>
        Over a period of approximately 3 months, participating veterans will complete two
        separate online surveys that will help us determine whether the online tool is helpful
        for veterans and for whom the online tool may be most helpful. You completed the first survey
        approximately 3 months ago. Today, you are being asked to complete the follow-up survey. Similar
        to the first survey, today’s follow-up survey will take about 45 minutes to complete.
        Participants were randomly assigned to try out the online tool during the first timepoint
        OR during the follow-up timepoint approximately three months later. If you did not try
        out the online tool during the first timepoint, you will be trying out the online tool today.
        Trying out the online tool includes completing a wellbeing assessment and receiving a report
        with feedback on your well-being as well as recommended resources for improving different
        aspects of your well-being.
      </span>
    </p>
    <br />
    <p class=MsoNoSpacing style=''>
      <b>
        <span style=''>
          What kinds of
          questions are included in the follow-up survey?
        </span>
      </b>
      <br>
      <span style=''>
        You will be asked a series of questions similar to the questions in the first survey,
        including questions about your health, well-being, and well-being related behaviors and
        thoughts. We will also ask you for your thoughts about the online tool at the timepoint you
        receive it.
      </span>
    </p>

    <p class=MsoNoSpacing>
      <b style=''>
        <span style=''></span>
      </b>
    </p>

    <p class=MsoNoSpacing>
      <b style=''>
        <span style=''>
          Why do you want me to participate? How
          did you get my name?
        </span>
      </b>
      <br>

      <!--<p class=MsoNoSpacing>-->
      <span style=''>
        Your
        name was obtained from VA records for the sole purpose of inviting you to
        participate in this research study. Your participation is completely voluntary.
        Our goal is to include men and women who <span>have transitioned from military to civilian life within the past two years</span>.
        We are seeking veterans with all types of military experiences and backgrounds —
        including veterans with different lengths of military service and racial/ethnic
        backgrounds. While we hope you will want to complete this survey as well as the
        3-month follow-up survey, completing today’s survey does not obligate you to complete
        the 3-month follow-up survey.
      </span>
    </p>

    <p class=MsoNoSpacing>
      <b style=''>
        <span style=''></span>
      </b>
    </p>

    <p class=MsoNoSpacing style=''>
      <b>
        <span style=''>
          Do I have to participate?
          Will participating or not affect the services or benefits I get from the VA?
        </span>
      </b>
      <br>

      <!--<p class=MsoNoSpacing>-->
      <span style=''>
        No.
        Participation is completely up to you.<span>
        </span>If you decide not to participate, it will not affect your VA benefits or
        access to services in any way. By clicking the “I consent/agree to participate”
        button and completing this online survey, you are agreeing to participate in
        this study. If you choose not to participate, you may exit this webpage.
      </span>
    </p>

    <p class=MsoNoSpacing><span style=''></span></p>

    <p class=MsoNoSpacing style=''>
      <b>
        <span style=''>
          Will my responses to
          study questions be kept private?
        </span>
      </b>
      <br>

      <!--<p class=MsoNoSpacing>-->
      <span style=''>
        Yes.<span>  </span>All information from study surveys and the
        online tool will be kept private. <span class=GramE>In order to</span> help us
        keep your information private, we ask that you do not type your name or other
        identifying information (e.g., address) in response to study questions. Your
        responses will be combined with those of approximately 60 other recent veterans
        who complete the same survey, and the survey results will be de-identified such
        that no individual can be identified. Your personally identifiable information
        will not be used in any reports. <span> </span>
      </span>
    </p>

    <p class=MsoNoSpacing><span style=''></span></p>

    <p class=MsoNormal style=''>
      <b>
        <span style=''>
          What steps are taken
          to ensure my privacy?
        </span>
      </b><span style=''>
        <br>
        Each participant gets a unique project identification number. This number — not
        your name or address — will be used to log into the online survey. Your name
        and address will be kept separate from your survey responses and you will
        complete the survey online on a secure site maintained by <span class=SpellE>ACESFed</span>.
        All identifying information about you will be kept in password-protected
        computer files on a secure computer system, or in locked filing cabinets in a
        locked storage area. These electronic and paper files will only be accessed by Dynamic
        Integrated Services/IPSOS for mailing purposes and research team personnel. <span style=''>
          Your paper records will be destroyed according
          to the VA Record retention schedule. For more information about the schedule,
        </span>
      </span><a href="http://www.va.gov/VHAPUBLICATIONS/RCS10/rcs10-1.pdf" target="_blank" style="color:blue;">
        <span style=''>
          click
          here
        </span>
      </a><span style=''>. </span><span style=''>
        In addition,
        to further protect your privacy, the MBTA Study has been approved by the VA
        Boston Health Care System Institutional Review Board.
      </span>
    </p>

    <p class=MsoNoSpacing style=''>
      <b>
        <span style=''>
          Who has access to my
          information?
        </span>
      </b>
      <br>

      <span style='font-size:11.0pt;'>
        Given your participation in this study’s first timepoint,
        your name and mailing address were given to Dynamic Integrated Services/IPSOS,
        so that they could mail you follow-up invitations and reminders.  
        If you opt out of participating in this study, your information will not be
        used for any other purposes. Only <span class=SpellE>ACESFed</span> (the
        company hosting the study surveys and online tool) and the study team at VA
        Boston Healthcare System will have access to your survey responses, which will
        be maintained separate from your names and other identifying information. Our
        study team has taken extensive measures to protect your privacy and ensure your
        confidentiality, as described previously. Please be assured that this
        information will not be shared with anyone else for any reason.
      </span>
    </p>

    <p class=MsoNoSpacing><span style=''></span></p>

    <p class=MsoNoSpacing style=''>
      <b>
        <span style=''>
          What are the benefits
          and risks of participating in the follow-up portion of this study?<span>
          </span>
        </span>
      </b>
      <br>

      <!--<p class=MsoNoSpacing>-->
      <span style=''>
        Completing the follow-up survey will help us learn from you and others whether our online tool may be beneficial to veterans transitioning out of the military. You will have the opportunity to try out the online tool if you did not already try out the online tool during the first timepoint. This tool may help you improve your well-being.
      </span>
    </p>

    <p class=MsoNoSpacing><span style=''></span></p>

    <p class=MsoNoSpacing>
      <span style=''>
        The
        risks associated with completing this follow-up survey are minimal. There are two main
        risks. First, you may experience feelings of distress while responding to
        questions, such as questions that ask about your well-being. If some questions
        feel too personal or distressing, it is okay to skip them. If you would like to
        talk with someone about how this study makes you feel, please see the list of
        helpful phone numbers below. The other risk is a loss of privacy. However, as
        explained above, we have taken <span class=GramE>a number of</span> steps to
        protect your privacy.<span>  </span>
      </span>
    </p>

    <p class=MsoNoSpacing><span style=''></span></p>

    <p class=MsoNoSpacing style=''>
      <b>
        <span style=''>
          Will I be compensated
          for participating in the survey?
        </span>
      </b>
      <br>

      <!--<p class=MsoNoSpacing>-->
      <span style=''>
        If you complete this follow-up survey, you will receive a $50 Amazon gift card.
      </span>
    </p>

    <p class=MsoNoSpacing>
      <b style=''>
        <span style=''></span>
      </b>
    </p>

    <p class=MsoNoSpacing style=''>
      <b>
        <span style=''>
          Who can I talk to if
          I still have questions?<span>  </span>
        </span>
      </b>
      <br>

      <!--<p class=MsoNormal style='margin-bottom:0in;'>-->
      <span style=''>
        If you have any questions about
        this project or your participation, please call the Principal Investigator of
        this study, Dr. Dawne Vogt at 857-364-5976<span style='color:navy'>.</span> If
        you call in the evenings or on weekends, feel free to leave a message.<span>  </span>We will get back to you as soon as possible.
      </span>
    </p>
    <br>

    <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;'>
      <b><span style=''></span></b>
    </p>

    <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;'>
      <b>
        <span style=''>
          Other Helpful Phone
          Numbers
        </span>
      </b><i style=''>
        <span style=''>
          (which you can call even if you
          don’t take part in the study)
        </span>
      </i><b style=''>
        <span style=''></span>
      </b>
    </p>

    <ul>
      <li>
        If
        you feel distressed or <span class=GramE>you want</span> to talk to someone
        about how you feel, or you are thinking about hurting yourself, help is
        available. Please call the Veterans Crisis Hotline at 988, send a text to
        838255, or
        <a href="https://www.veteranscrisisline.net/" target="_blank" style="color:blue;">
          <span style=''>click here</span>
        </a>
        <span style=''>
          to access the Veterans Crisis
          Line.
        </span>
      </li>

      <li>
        If
        questions in the study survey made you distressed or you want to talk to
        someone about how those questions made you feel, please call Dr. Katherine
        Iverson, who is a clinical psychologist and a member of our research staff, at (857)
        364-2066. If Dr. Iverson is unavailable, and you are in crisis, you are
        encouraged to go to your nearest emergency room. Otherwise, please leave a
        message, and she will return your call Monday-Friday within 24 hours. If you
        have a therapist, you might also want to discuss this study with him or her.
      </li>

      <li>
        If
        you want to talk to someone about your <span style=''>
          general
          rights as a study participant
        </span>, please call the VA Boston Research
        Compliance Officer at 857-364-4182.
      </li>

      <li>
        If
        you need emergency care because you took part in this study, the VA will
        provide care to you.<span>  </span>Be sure to tell the
        VA that you are in a research study. Please call 911 if you have a medical
        emergency.<span>  </span>
      </li>


      <li>
        If
        you aren’t <span class=GramE>sure</span> you are having an emergency and don’t
        want to wait until regular business hours, you may also call the VA Boston
        HealthCare System at 617-232-9500 and ask the operator to page the psychiatrist
        on call. Tell the operator that you are a research participant.
      </li>
    </ul>



    <p class=MsoListParagraph style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:.25in;text-indent:-.25in;color:red;text-align:center'>
      <b style=''>
        <span style=''>
          PLEASE COMPLETE THE SURVEY ON A COMPUTER OR TABLET WITH INTERNET ACCESS, AS YOU MAY HAVE TROUBLE VIEWING THE SURVEY ON A MOBILE DEVICE.  ALSO, PLEASE NOTE THAT YOUR SCORES ARE SAVED EVEN IF YOU DON’T SEE THEM WHEN YOU GO BACK TO A PRIOR PAGE. ALSO, IF YOU CLOSE YOUR WEB BROWSER OR LEAVE THE WEBSITE AT ANY POINT, YOU WILL BE ABLE TO LOG BACK IN LATER AND RESUME IN THE SECTION WHERE YOU LEFT OFF.</span>
      </b>
    </p>

    <p class=MsoNormal style='margin-bottom:0in;'>
      <span style=''>
        You may
        print this
        page

      </span><span style=''>
        for your records. You may also contact the study
        team to have a copy of this information mailed to you.
      </span>
    </p>
    <br>

    <p class=MsoNormal style='margin-bottom:0in;'>
      <b style=''>
        <span style=''>
          By
          clicking the button below, you are confirming that you have read <span class=GramE>all of</span> the study information above and agree to participate
          in the study.
        </span>
      </b>
    </p>


    <button color="primary" style="margin:10px;width:100%;" mat-button (click)="gotoSurvey()">I consent/agree to participate</button>




  </div>


</div>

<div style="padding-top:15px;">
  <router-outlet></router-outlet>
</div>
