import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import {
  TNTUser, TNTResponseDetail, TNTAnswer, TNTAnswerSet, TNTUserGroupDetail, AnswerType,
  TNTUserGroup, TNTQuestion, TNTResource, TNTResponse, TNTSurvey, UserInput, UserInformation, DomainStatus, ResourceCategory
} from '../../models/models';

import { SurveyAPI } from '../../services/surveyAPI.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ElementRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})


export class SurveyComponent implements  AfterViewChecked {
  public AnswerType = AnswerType;
  public token?: string | any;
  public user: UserInformation;
  public DomainStatus = DomainStatus;
  public ResourceCategory = ResourceCategory;

  @ViewChild('autosize') autosize?: CdkTextareaAutosize;

  constructor(
    private route: ActivatedRoute, private router: Router, private elRef: ElementRef, private sapi: SurveyAPI, private _ngZone: NgZone, private dialog: MatDialog
  ) {
    this.user = new UserInformation(this.sapi, this.dialog,router);
  }


  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize?.resizeToFitContent(true));
  }

  ngAfterViewChecked(): void {
    if (this.user.currentQuestion!=null) {
      if (this.user.currentQuestion.image != null) {
        let matcc: HTMLElement = this.elRef.nativeElement.getElementsByClassName("mat-card-content")[0];
        if (matcc.style.backgroundImage == '') {
          matcc.style.backgroundRepeat = 'no-repeat';
          matcc.style.backgroundPosition = 'right';
          matcc.style.backgroundSize = 'contain';
          matcc.style.backgroundImage = "url('data:image/png;base64," + this.user.currentQuestion.image + "')";
          matcc.style.minHeight = '350px';
        }
      } else {
        let matcc: HTMLElement = this.elRef.nativeElement.getElementsByClassName("mat-card-content")[0];
        if (matcc.style.backgroundImage != '') {
          matcc.style.backgroundImage = '';
          matcc.style.height = '';
        }
      }
    }
  }

  ngAfterContentInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    this.user.getUserInformation(this.token);
  }

  public bookend(answer: TNTAnswer):string  {    
    return answer.answerText.split(":=")[1];
  }
  public questionText(q: string): string {
    if (q.indexOf(":=") > 0) {
      return q.split(":=")[1];
    } else {
      return q;
    }
  }
  public preText(q: string): string {
    if (q.indexOf(":=") > 0) {
      return q.split(":=")[0];
    } else {
      return "";
    }
  }
}
