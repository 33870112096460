<ng-container *ngIf="user.loading">
  <mat-spinner style="margin:0 auto;" mode="indeterminate"></mat-spinner>
</ng-container>
<ng-container *ngIf="!user.loading">

  <!--Usual Finish screen for all surveys, except for the assessment-->
  <div *ngIf="user.surveyCompleted && !user.reportMode">
    <mat-card class="basic-card">
      <mat-card-header>
        <mat-card-title style="margin:0 auto;text-align:center;font-weight:bold;">
          <div *ngIf="user.currentSurvey.name != 'TOOL PART 1: Well-being Assessment' && user.currentSurvey.name != 'BACKGROUND QUESTIONS<br>(These questions are not part of the tool)'" [innerHtml]="user.currentSurvey.name"></div>
          <div *ngIf="user.currentSurvey.name == 'TOOL PART 1: Well-being Assessment'">TOOL PART 2: Well-Being Report and Resources</div>
        </mat-card-title>
        <mat-card-subtitle>
          <div [innerHtml]="user.currentSurvey.finishStatement"></div>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <h4 *ngIf="user.loaded">
          Click the Next Button Below to Continue
        </h4>
      </mat-card-content>
      <mat-card-actions>
        <button *ngIf="user.surveyCompleted" mat-stroked-button align="end" (click)="user.nextSurvey()"> Next </button>
      </mat-card-actions>
      <mat-card-footer>
        <h4><!--footer--></h4>
      </mat-card-footer>
    </mat-card>
  </div>


  <div *ngIf="user.allSurveysCompleted">
    <mat-card class="basic-card">
      <mat-card-header style="text-align:center;">
        <mat-card-subtitle>
          <div>
            You have completed all available questionnaires. You may also access your prior responses and report from the menu at top right. Thank you
          </div>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
      <mat-card-footer>
        <h4><!--footer--></h4>
      </mat-card-footer>
    </mat-card>
  </div>

  <div *ngIf="!user.reportMode && !user.allSurveysCompleted">
    <div *ngIf="user.questionOrder==0">
      <mat-card class="basic-card">
        <mat-card-header>
          <mat-card-title style="margin: 0 auto; text-align: center; font-weight: bold; ">
            <span [innerHtml]="user.currentSurvey.name"></span>
          </mat-card-title>
          <mat-card-subtitle>
            <div [innerHtml]="user.currentSurvey.description"></div>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <h4 style="text-align:center;">Press the Next Button Below to Begin.</h4>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button align="end" (click)="user.nextGroup(user.currentQuestion)"> Next </button>
        </mat-card-actions>
        <mat-card-footer>
          <h4><!--footer--></h4>
        </mat-card-footer>
      </mat-card>
    </div>










    <!--######## QUESTION GROUP ################ -->
    <ng-container *ngIf="!user.surveyCompleted && user.questionOrder>0 && user.currentQuestion.grouped">

      <!--NON-HOMOGENOUS GROUP-->

      <ng-container *ngIf="!user.currentQuestion.homogenous">


        <mat-card class="basic-card">

          <mat-card-header>
            <mat-card-title style="margin: 0 auto; text-align: center; font-weight: bold; ">
              <span [innerHtml]="user.currentSurvey.name"></span>
            </mat-card-title>
            <mat-card-title *ngIf="user.currentQuestion.instructions!=null">
              <span [innerHtml]="user.currentQuestion.instructions"></span>
            </mat-card-title>
          </mat-card-header>

          <ng-container *ngFor="let q of user.currentQuestion.siblings">

            <mat-card-header>
              <mat-card-subtitle style="border-bottom:dashed 1px black;">
                <span class="questionNumber"><span [innerHtml]="q.questionText"></span></span>
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content style="margin-left:20px;">
              <div *ngIf="q.answerSet.answerType == AnswerType.MultipleChoiceSingleAnswer">
                <mat-radio-group class="example-radio-row">

                  <span *ngFor="let a of q.answerSet.answers">
                    <span *ngIf="this.bookend(a)!=null && a.answerScore==1" [innerHtml]="this.bookend(a)" style="word-wrap: break-word; white-space: normal; padding-left: 20px;"></span>
                    <mat-radio-button class="example-radio-button" [value]="a.answerID" (change)="user.updateGroupedAnswer($event,q,a)">
                      <span *ngIf="this.bookend(a)==null" [innerHtml]="a.answerText" style="word-wrap: break-word; white-space: normal; vertical-align: top;"></span>
                      <span *ngIf="this.bookend(a)!=null" [innerHtml]="a.answerScore" style="word-wrap: break-word; white-space: normal; vertical-align: top;"></span>
                    </mat-radio-button>
                    <span *ngIf="this.bookend(a)!=null && a.answerScore>1" [innerHtml]="this.bookend(a)" style="word-wrap: break-word; white-space: normal; padding-left:20px;"></span>
                  </span>

                </mat-radio-group>
              </div>
              <div *ngIf="q.answerSet.answerType == AnswerType.MultipleChoiceMultipleAnswer">
                <mat-checkbox class="example-radio-button" style="display:block;" *ngFor="let a of q.answerSet.answers" [id]="a.answerID" (change)="user.updateGroupedMultipleChoiceUserInputValue($event,q,a)">
                  <span [innerHtml]="a.answerText" style="word-wrap:break-word;white-space:normal;vertical-align:top;"></span>
                </mat-checkbox><br />
              </div>
              <div *ngIf="q.answerSet.answerType == AnswerType.UserInput">
                <mat-form-field class="example-full-width" appearance="fill" *ngFor="let a of q.answerSet.answers" style="width:100%;">
                  <mat-label [innerHtml]="a.answerText"></mat-label>
                  <input *ngIf="a.userType == 'int'" matInput [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,q,a)">
                  <textarea *ngIf="a.userType == 'string'" matInput style="width:100%;"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5" [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,q,a)"></textarea>
                </mat-form-field>
              </div>
              <div *ngIf="q.answerSet.answerType == AnswerType.UserInputWithNA">
                <div *ngFor="let a of q.answerSet.answers" appearance="fill">
                  <mat-form-field class="example-full-width" appearance="fill" *ngIf="a.userDefined">
                    <mat-label *ngIf="a.userDefined" [innerHtml]="a.answerText"></mat-label>
                    <input *ngIf="a.userDefined" matInput [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,q,a)">
                  </mat-form-field>
                  <mat-checkbox *ngIf="!a.userDefined" class="example-radio-button" (change)="user.clearGroupedUserInputNA($event,q,a)" [id]="a.answerID">
                    <span [innerHtml]="a.answerText"></span>
                  </mat-checkbox>
                </div>
              </div>
            </mat-card-content>

          </ng-container>


          <mat-card-actions>
            <button mat-stroked-button class="previousButton" *ngIf="user.questionOrder>0" (click)="user.previousGroup(user.currentQuestion)"> Previous </button>
            <button mat-stroked-button align="end" (click)="user.nextGroup(user.currentQuestion)"> Next  </button>
          </mat-card-actions>

          <mat-card-footer>
            <h4><!--footer--></h4>
          </mat-card-footer>
        </mat-card>


      </ng-container>
      <!--HOMOGENOUS GROUP-->



      <ng-container *ngIf="user.currentQuestion.homogenous">
        <mat-card class="basic-card">
          <mat-card-header>
            <mat-card-title style="margin: 0 auto; text-align: center; font-weight: bold; ">
              <span [innerHtml]="user.currentSurvey.name"></span>
            </mat-card-title>
            <mat-card-title *ngIf="user.currentQuestion.instructions!=null">
              <span [innerHtml]="user.currentQuestion.instructions"></span>
            </mat-card-title>
          </mat-card-header>

          <mat-card-content *ngIf="user.currentQuestion.answerSet.answerType == AnswerType.MultipleChoiceSingleAnswer">

            <table style="width:100%;">
              <tr>
                <td style="width: 30%; word-wrap: break-word;" [innerHtml]="preText(user.currentQuestion.questionText)"></td>
                <td>
                  <div style="display:flex;flex-direction:row;justify-content:space-evenly;">
                    <div *ngFor="let a of user.currentQuestion.answerSet.answers" [innerHtml]="a.answerText" style="text-align:center; word-wrap: anywhere; width: calc(100% / {{user.currentQuestion.answerSet.answers.length}});"></div>
                  </div>
                </td>
              </tr>
              <tr *ngFor="let q of user.currentQuestion.siblings">
                <td [innerHtml]="questionText(q.questionText)" style="border-bottom:solid 1px black;"></td>
                <td style="border-bottom:solid 1px black;">
                  <mat-radio-group style="display:flex;flex-direction:row;justify-content:space-evenly;">
                    <mat-radio-button class="radio-empty" style="
                                    padding-left: calc(50% / {{user.currentQuestion.answerSet.answers.length}});
                                    width: calc(100% / {{user.currentQuestion.answerSet.answers.length}});"
                                      *ngFor="let a of q.answerSet.answers" [value]="a.answerID"
                                      (change)="user.updateGroupedAnswer($event,q,a)" aria-label="a.answerText">
                    </mat-radio-button>
                  </mat-radio-group>
                </td>
              </tr>
            </table>

          </mat-card-content>
          <mat-card-content *ngIf="user.currentQuestion.answerSet.answerType == AnswerType.UserInput">

            <table style="width:100%;">
              <tr *ngFor="let q of user.currentQuestion.siblings">
                <td [innerHtml]="q.questionText" style="border-bottom:solid 1px black;width: 30%; word-wrap: break-word;"></td>
                <td style="border-bottom:solid 1px black;">

                  <mat-form-field class="example-full-width" appearance="fill" *ngFor="let a of q.answerSet.answers" style="width:100%;">
                    <mat-label [innerHtml]="a.answerText"></mat-label>
                    <input *ngIf="a.userType == 'int'" matInput [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,q,a)" type="number">
                    <textarea *ngIf="a.userType == 'string'" matInput style="width:100%;"
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="5" [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,q,a)"></textarea>
                  </mat-form-field>


                  <!--<input *ngIf="a.userType == 'int'" matInput [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,q,a)" type="number">
                  <textarea *ngIf="a.userType == 'string'" matInput style="width:100%;"
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="5" [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,q,a)"></textarea>-->
                </td>
              </tr>
            </table>

          </mat-card-content>

          <mat-card-actions>
            <button mat-stroked-button class="previousButton" *ngIf="user.questionOrder>0" (click)="user.previousGroup(user.currentQuestion)"> Previous </button>
            <button mat-stroked-button align="end" (click)="user.nextGroup(user.currentQuestion)"> Next  </button>
          </mat-card-actions>

          <mat-card-footer>
            <h4><!--footer--></h4>
          </mat-card-footer>
        </mat-card>
      </ng-container>
    </ng-container>


    <!--######   SINGLE QUESTION ################-->
    <ng-container *ngIf="!user.surveyCompleted && user.questionOrder>0 && !user.currentQuestion.grouped">
      <mat-card class="basic-card">
        <mat-card-header>
          <mat-card-title style="margin: 0 auto; text-align: center; font-weight: bold; ">
            <span [innerHtml]="user.currentSurvey.name"></span>
          </mat-card-title>
          <mat-card-title *ngIf="user.currentQuestion.instructions!=null">
            <span [innerHtml]="user.currentQuestion.instructions"></span>
          </mat-card-title>
          <mat-card-subtitle>
            <span class="questionNumber"><span [innerHtml]="user.currentQuestion.questionText"></span></span>
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <div *ngIf="user.currentQuestion.answerSet.answerType == AnswerType.MultipleChoiceSingleAnswer">
            <mat-radio-group class="example-radio-group" (ngModel)="user.currentAnswerId">
              <mat-radio-button class="example-radio-button" *ngFor="let a of user.currentQuestion.answerSet.answers" [value]="a.answerID" (change)="user.updateGroupedAnswer($event,user.currentQuestion,a)">
                <span [innerHtml]="a.answerText" style="word-wrap: break-word; white-space: normal; vertical-align: top;"></span>
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="user.currentQuestion.answerSet.answerType == AnswerType.MultipleChoiceMultipleAnswer">
            <mat-checkbox class="example-radio-button" style="display:block;" *ngFor="let a of user.currentQuestion.answerSet.answers" [id]="a.answerID" (change)="user.updateGroupedMultipleChoiceUserInputValue($event,user.currentQuestion,a)">
              <span [innerHtml]="a.answerText" style="word-wrap:break-word;white-space:normal;vertical-align:top;"></span>
            </mat-checkbox><br />
          </div>
          <div *ngIf="user.currentQuestion.answerSet.answerType == AnswerType.UserInput">
            <mat-form-field class="example-full-width" appearance="fill" *ngFor="let a of user.currentQuestion.answerSet.answers" style="width:100%;">
              <mat-label [innerHtml]="a.answerText"></mat-label>
              <input *ngIf="a.userType == 'int'" matInput [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,user.currentQuestion,a)" type="number">
              <textarea *ngIf="a.userType == 'string'" matInput style="width:100%;"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5" [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,user.currentQuestion,a)"></textarea>
            </mat-form-field>
          </div>
          <div *ngIf="user.currentQuestion.answerSet.answerType == AnswerType.UserInputWithNA">
            <div *ngFor="let a of user.currentQuestion.answerSet.answers" appearance="fill">
              <mat-form-field class="example-full-width" appearance="fill" *ngIf="a.userDefined">
                <mat-label *ngIf="a.userDefined" [innerHtml]="a.answerText"></mat-label>
                <input *ngIf="a.userDefined" matInput [id]="a.answerID" (change)="user.updateGroupedUserInputValue($event,user.currentQuestion,a)">
              </mat-form-field>
              <mat-checkbox *ngIf="!a.userDefined" class="example-radio-button" (change)="user.clearGroupedUserInputNA($event,user.currentQuestion,a)" [id]="a.answerID">
                <span [innerHtml]="a.answerText"></span>
              </mat-checkbox>
            </div>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <button mat-stroked-button class="previousButton" *ngIf="user.questionOrder>0" (click)="user.previousGroup(user.currentQuestion)"> Previous </button>
          <button mat-stroked-button align="end" (click)="user.nextGroup(user.currentQuestion)"> Next  </button>
        </mat-card-actions>

        <mat-card-footer>
          <h4><!--footer--></h4>
        </mat-card-footer>
      </mat-card>
    </ng-container>

  </div>


</ng-container>
